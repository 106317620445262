import { Field, Form, Formik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Logo from '../../assets/images/svg/app/Logo.svg'
import FieldInput from '../../components/FieldInput'
import useRecoveryPassword from '../../hooks/password/useRecoveryPassword'
import { RecoveryPasswordSchema } from '../../schemas/RecoveryPasswordSchema'

export const ForgotPasswordPage = () => {

    const navigate = useNavigate()
    const recoveryPassword = useRecoveryPassword()

    const onSubmit = async (values, formik) => {
        const res = await  recoveryPassword.handleRecoveryPassword(values)
        if(res) {
            navigate('/login')
            //formik.resetForm()
        }
    }

    return (
        <div className="min-h-screen flex items-center justify-center px-4 sm:px-6 lg:px-8 py-12 login">
            <div className="w-full max-w-lg z-10">
                <div>
                    <img
                        className="mx-auto w-auto h-28"
                        src={Logo}
                        alt="Workflow"
                    />
                </div>
                <Formik
                    initialValues={{
                        email: '',
                    }}
                    validationSchema={RecoveryPasswordSchema}
                    onSubmit={onSubmit}
                >
                    {({ values, errors}) => (
                        <Form>
                            <div className="mt-20" action="#" method="POST">
                                <input type="hidden" name="remember" defaultValue="true" />
                                <div className="">
                                    <Field
                                        name="email"
                                    >
                                        {
                                            (atrs) => <FieldInput
                                                {...atrs}
                                                className={"mt-5"}
                                                label="Correo"
                                                type="email"
                                            ></FieldInput>
                                        }
                                    </Field>
                                </div>

                                <div className='mt-8'>
                                    <button
                                        type="submit"
                                        className="group relative w-full flex justify-center btn-prymary btn-medium"
                                        disabled={recoveryPassword.loading}
                                    >
                                        Recuperar contraseña
                                    </button>
                                </div>

                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}