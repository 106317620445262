import { useState } from "react"
import { errorAlert, successAlert } from "../../utils/AlertToast"
import { api } from "../../utils/ApiMethods"

const useResetPassword = () => {

    const [loading, setLoading] = useState(false)

    const handleResetPassword = async (values) => {

        setLoading(true)

        try {
            const response = await api().post('resetPasswordByCode').sendJson(values)
            const data = await response.json()

            setLoading(false)
            if (response.status === 200) {
                successAlert('', "La contraseña se ha reestablecido.")
                return true
            }

            else
                errorAlert('', data.message)

        } catch (e) {

            setLoading(false)
            errorAlert()
            console.log(e)


        }
        return false
    }

    return { handleResetPassword, loading }
}

export default useResetPassword