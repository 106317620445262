import { useState } from "react"
import { useAuth } from "../../context/AuthContext"
import { errorAlert, successAlert } from "../../utils/AlertToast"
import { api } from "../../utils/ApiMethods"

const useCreateStore = () => {

    const { authAction, auth } = useAuth()

    const [loading, setLoading] = useState(false)

    const handleCreateStore = async (values, msg = null) => {
        setLoading(true)
        try {

            const response = await api(auth.token).post('createStore').sendForm({ ...values })
            const data = await response.json()
            setLoading(false)
            if (response.status === 201) {
                successAlert('', msg ?? data.message)
                return data
            }
            else
                errorAlert('', data.message)

        } catch (e) {
            console.log(e)
            setLoading(false)
            errorAlert()
        }

        return false

    }
    return { handleCreateStore, loading }
}

export default useCreateStore