import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { errorAlert, successAlert, unauthorizedUser } from '../../utils/AlertToast'
import { api } from '../../utils/ApiMethods'

const useUpdateProfile = () => {
    const { authAction, auth } = useAuth()
    const [loading, setLoading] = useState(false)

    const handleUpdateProfile = async (values) => {
        setLoading(true)
        try {
            const response = await api(auth.token).post('updateAdminProfile').sendForm({
                ...values
            })
            const data = await response.json()
            setLoading(false)

            if (response.status === 200) {
                successAlert('', 'Perfil actualizado')
                return true
            } else if (response.status !== 401) {
                errorAlert('Error', data.message)
            }
            unauthorizedUser(authAction, data.message)

        } catch (e) {
            setLoading(false)
            errorAlert()
        }
        return false
    }

    return { handleUpdateProfile, loading }

}

export default useUpdateProfile