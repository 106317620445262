import React from 'react';
import { classNames } from '../utils/CssHelpers';

export const Th = ({ children, className }) =>
    <th
        scope="col"
        className={classNames(
            'text-white uppercase pb-3 font-semibold text-sm text-left',
            className
        )}
    >
        {children}
    </th>


export const Td = ({ children, className }) =>
    <td
        scope="col"
        className={classNames(
            '',
            'text-sm text-darkgray',
            className
        )}
    >
        {children}
    </td>

export const Table = ({ children, className = "table-default" }) =>
    <div className="shadow bg-tailgreen w-full pt-4 rounded-t-xl">
        <div className='overflow-x-auto'>
            <table className={className}>
                {children}
            </table>
        </div>
    </div>
