import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import React from 'react';

const centerInit = {
    lat: -3.745,
    lng: -38.523
};

const lib = ['places'];

export const Map = ({center = centerInit}) => {

    return (
            <LoadScript
                googleMapsApiKey={process.env.REACT_APP_API_API_MAPS ?? ''}
                libraries={lib}
            >
                <div className="h-full w-full min-h-[200px] max-h-[250px]">
                    <GoogleMap
                        mapContainerStyle={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '9px',
                            overflow: 'hidden'
                        }}
                        center={center}
                        zoom={10}
                    >
                        <Marker
                            position={center}
                        />
                    </GoogleMap>
                </div>
            </LoadScript>
    )
}