import React, { useEffect, useState, useRef, Fragment } from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import { DotsCircleHorizontalIcon, EyeIcon } from '@heroicons/react/solid'
import useGetSales from '../../hooks/stores/useGetSales'
import SearchInput from '../../components/SearchInput'
import { Table, Td, Th } from '../../components/Table'
import OrderIcon from '../../components/OrderIcon'
import { useDebounce } from '../../hooks/utils/useDebounce'
import useGetAllPetTypes from '../../hooks/services/useGetAllPetTypes'
import useGetAllServicesTypes from '../../hooks/services/useGetAllServicesTypes'
import AvatarPhotodefault from '../../assets/images/png/app/AvatarPhotodefault.png'
import Pagination from '../../components/Pagination'
import { classNames } from '../../utils/CssHelpers'
import useUpdateAppointmentStatusPaidToProvider from '../../hooks/appointments/useUpdateAppointmentStatusPaidToProvider'
import { Dialog, Switch, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import useGetAppointmentsInfo from '../../hooks/appointments/useGetAppointmentsInfo'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);


const spaces = Array(100).fill("\u00A0\u00A0").join("")

const options = {
    plugins: {
        legend: {
            // display: false,
            position: 'right',
            labels: {
                textAlign: 'left',
                boxWidth: 10,
                fontSize: 7,
            },
        },
    },
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
}

export const VendorSalesPage = () => {

    const { id } = useParams()
    const getSales = useGetSales()
    const getAllPetTypes = useGetAllPetTypes()
    const getAllServiceTypes = useGetAllServicesTypes()
    const updateAppointmentStatusPaidToProvider = useUpdateAppointmentStatusPaidToProvider()
    const getAppointmentsInfo = useGetAppointmentsInfo()

    const [search, setSearch] = useState('')
    const fieldSearch = { value: search, onChange: (e) => setSearch(e.target.value) }
    const debouncedSearch = useDebounce(search, 500);

    const [openModalStatusPaid, setOpenModalStatusPaid] = useState(false)
    const cancelButtonModalUpdatePaidRef = useRef(null)

    const [appointment, setAppointment] = useState(null)

    const formatCurrency = amount => {
        const options = { style: 'currency', currency: 'USD' };
        const numberFormat = new Intl.NumberFormat('en-US', options);

        return numberFormat.format(amount);
    }

    const formatPercentage = percentage => {
        return (percentage * 100).toFixed(2)
    }

    const renderAppointmentStatus = (param) => {
        switch(param) {
            case 0:
                return 'Enviada';
            case 1:
                return 'Aceptada';
            case 2:
                return 'Rechazada';
            case 3:
                return 'Cancelada';
            case 4:
                return 'En Proceso';
            case 5:
                return 'Terminada';
            default:
                return '';
        }
    }

    const updateStatusPaidToProvider = async (appointment) => {

        const resp = await updateAppointmentStatusPaidToProvider.handleUpdateStatusPaidProvider({
            appointmentObjectId: appointment?.objectId,
            isPaidToProvider: appointment?.isPaidToProvider === 1 ? 0 : 1
        })
        if (resp)
            getSales.setParams({ ...getSales.params, })  /*  */

        setOpenModalStatusPaid(false)
    }

    const showModalStatusPaid = (appointment) => {
        setAppointment(appointment)
        setOpenModalStatusPaid(true)
    }

    const onChangeStatusPaidToProvider = (e) => {
        const statusPaidToProviderFilterAux = e.target.value
        const { statusFilter, ...resp } = getSales.params
        getSales.setParams({
            ...resp,
            pageNum: 0,
            ...(statusPaidToProviderFilterAux !== '' ? { statusPaidToProviderFilter: statusPaidToProviderFilterAux } : {}),
        })
    }

    const onChangeField = (field) => {
        getSales.setParams({
            ...getSales.params,
            sortField: field,
            sortDir: getSales.params.sortDir * -1
        })
    }

    const onChangeStatus = (e) => {
        const statusFilterAux = e.target.value
        const { statusFilter, ...resp } = getSales.params
        getSales.setParams({
            ...resp,
            pageNum: 0,
            ...(statusFilterAux !== '' ? { statusFilter: statusFilterAux } : {}),
        })
    }

    const onChangePage = (p) => {
        getSales.setParams({
            ...getSales.params,
            pageNum: (p - 1)
        })
    }

    const onChangeServiceType = (e) => {
        const serviceTypeFilterAux = e.target.value
        const { serviceTypeFilter, ...resp } = getSales.params
        getSales.setParams({
            ...resp,
            pageNum: 0,
            ...(serviceTypeFilterAux !== '' ? { serviceTypeFilter: serviceTypeFilterAux } : {}),
        })
    }

    const onChangePetType = (e) => {
        const petTypeFilterAux = e.target.value
        const { petTypeFilter, ...resp } = getSales.params
        getSales.setParams({
            ...resp,
            pageNum: 0,
            ...(petTypeFilterAux !== '' ? { petTypeFilter: petTypeFilterAux } : {}),
        })
    }

    const perServiceType = {
        labels: getAllServiceTypes?.types.map(type => {
            const info = getAppointmentsInfo?.info?.perServiceType?.find(perService => {
                return type?.name === perService?.name
            })
            return `${type?.name} ${info ? formatPercentage(info?.percentage) : "0.00"}%${spaces}`
        }),
        datasets: [
            {
                label: '# of Votes',
                data: getAllServiceTypes?.types.map(type => {
                    const info = getAppointmentsInfo?.info?.perServiceType?.find(perService => {
                        return type?.name === perService?.name
                    })
                    return info ? (info?.amount) : 0
                }),
                backgroundColor: [
                    '#FF8A8A',
                    '#80E0FF',
                    '#A698FF',
                    '#75D365',
                ],
                borderColor: [
                    '#FF8A8A',
                    '#80E0FF',
                    '#A698FF',
                    '#75D365',
                ],
                borderWidth: 1,
            },
        ],
    };
    const perPetType = {
        labels: getAllPetTypes?.types.map(type => {
            const info = getAppointmentsInfo?.info?.perPetType?.find(perPet => {
                return type?.name === perPet?.name
            })
            return `${type?.name} ${info ? formatPercentage(info?.percentage) : "0.00"}%${spaces}`
        }),
        datasets: [
            {
                label: '# of Votes',
                data: getAllPetTypes?.types.map(type => {
                    const info = getAppointmentsInfo?.info?.perPetType?.find(perPet => {
                        return type?.name === perPet?.name
                    })
                    return info ? (info?.amount) : 0
                }),
                backgroundColor: [
                    '#FF8A8A',
                    '#80E0FF',
                    '#A698FF',
                    '#75D365',
                    '#FFE8BA'
                ],
                borderColor: [
                    '#FF8A8A',
                    '#80E0FF',
                    '#A698FF',
                    '#75D365',
                    '#FFE8BA'
                ],
                borderWidth: 1,
            },
        ],
    };

    useEffect(() => {
        getSales.handleGetSales(id)
    }, [getSales.params])

    useEffect(() => {
        getSales.setParams({
            ...getSales.params,
            filter: debouncedSearch,
            pageNum: 0
        })
        return () => { };
    }, [debouncedSearch])

    useEffect(() => {
        getAllServiceTypes.handleGetAllServiceTypes()
        getAppointmentsInfo.handleGetAppointmentsInfo(id)
        getAllPetTypes.handleGetAllPetTypes()
        return () => { };
    }, [])

    return (

        <div className="-my-2 sm:-mx-6 lg:-mx-8">

            <section className='mb-5 mt-2' style={{display: 'flex', justifyContent: 'center'}}>
            <Link to={`/proveedores/${id}/detalle`} replace className='font-bold text-extradarkgray mr-3'> Información General</Link>
            <Link to={`/proveedores/${id}/servicios`} replace className='font-bold text-extradarkgray mr-3 ml-3'> Listado de Servicios</Link>
            <Link to={`/proveedores/${id}/ventas`} replace className='font-bold text-tailgreen ml-3'>Historial de Ventas</Link>
            </section>

            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">

                <div className='flex flex-col lg:flex-row mb-5 items-center min-h-52 gap-8'>
                    <div className='lg:w-1/2 w-full'>

                        <div className="mb-8 md:grid md:grid-cols-3 lg:block">
                            <div className="bg-[#FFE8BA] rounded-lg px-6 py-4 mt-5 lg:mr-5 block lg:inline-block">
                                <p className="text-lg font-semibold leading-6 mb-3">
                                    Servicios agendados
                                </p>
                                <h2 className="leading-6 font-bold text-2xl">
                                    {getAppointmentsInfo?.info?.info?.scheduledAppointments ?? 0}
                                </h2>
                            </div>
                            <div className="bg-[#CEFCFF] rounded-lg px-6 py-4 mt-5 lg:mr-5 block lg:inline-block">
                                <p className="text-lg font-semibold leading-6 mb-3">
                                    Ganancias globales
                                </p>
                                <h2 className="leading-6 font-bold text-2xl">
                                    {formatCurrency(getAppointmentsInfo?.info?.info?.globalIncome ?? 0)}
                                </h2>
                            </div>
                            <div className="bg-[#D7FED6] rounded-lg px-6 py-4 mt-5 lg:mr-5 block lg:inline-block">
                                <p className="text-lg font-semibold leading-6 mb-3">
                                    Utilidad global
                                </p>
                                <h2 className="leading-6 font-bold text-2xl">
                                    {formatCurrency(getAppointmentsInfo?.info?.info?.globalUtility ?? 0)}
                                </h2>
                            </div>
                        </div>
                        <div className="md:w-[28rem] w-full">
                            <SearchInput
                                className='w-full'
                                field={fieldSearch}
                                meta={{ touched: search }}
                                label='Buscar'
                            ></SearchInput>
                        </div>

                    </div>

                    <div className='grid grid-cols-1 lg:grid-cols-2 w-full lg:w-1/2'>
                        <div className="mb-4">
                            <Pie data={perPetType} options={options} />
                        </div>
                        <div className="mb-4">
                            <Pie data={perServiceType} options={options} />
                        </div>
                    </div>
                </div>

                <div className='grid grid-cols-1 pb-5'>
                    {
                        getSales.loading ?
                            <div className='flex justify-center mt-20'>
                                <p className='h2'> Cargando...</p>
                            </div>
                            :
                            <>
                                {
                                    (getSales.sales && getSales.sales.length !== 0) ?
                                        <Table>
                                            <thead className="">
                                                <tr>
                                                    <Th className="px-6 min-w-[100px]">
                                                        <OrderIcon
                                                            field={"appointments.identifier"}
                                                            params={getSales.params}
                                                            onChange={onChangeField}
                                                        >ID</OrderIcon>
                                                    </Th>
                                                    <Th className="px-6 min-w-[100px]">
                                                        <OrderIcon
                                                            field={"users.name"}
                                                            params={getSales.params}
                                                            onChange={onChangeField}
                                                        >Cliente</OrderIcon>
                                                    </Th>
                                                    <Th className="px-6 min-w-[100px]">
                                                        <OrderIcon
                                                            field={"appointments.date"}
                                                            params={getSales.params}
                                                            onChange={onChangeField}
                                                        >Fecha y Hora</OrderIcon>
                                                    </Th>
                                                    <Th className="px-6 min-w-[100px]" >
                                                        <select
                                                            className='multiselect__input bg-tailgreen text-white uppercase font-semibold text-sm pl-0'
                                                            onChange={onChangePetType}
                                                        >
                                                            <option value="" className=''>Tipo de mascota</option>
                                                            {
                                                                getAllPetTypes.types.map(t => (
                                                                    <option value={t.objectId} key={t.objectId}>{t.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </Th>
                                                    <Th className="px-6 min-w-[100px]" >
                                                        <OrderIcon
                                                            field={"pets.name"}
                                                            params={getSales.params}
                                                            onChange={onChangeField}
                                                        >Nombre de Mascota</OrderIcon>
                                                    </Th>
                                                    <Th className="px-6 min-w-[100px]" >
                                                        <select
                                                            className='multiselect__input bg-tailgreen text-white uppercase font-semibold text-sm pl-0'
                                                            onChange={onChangeServiceType}
                                                        >
                                                            <option value="" className=''>Categoría de servicio</option>
                                                            {
                                                                getAllServiceTypes.types.map(t => (
                                                                    <option value={t.objectId} key={t.objectId}>{t.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </Th>
                                                    <Th className="px-6 min-w-[100px]" >
                                                        <OrderIcon
                                                            field={"appointments.price"}
                                                            params={getSales.params}
                                                            onChange={onChangeField}
                                                        >Precio</OrderIcon>
                                                    </Th>
                                                    <Th className="px-6 min-w-[100px]" >
                                                    <select
                                                                className='multiselect__input bg-tailgreen text-white uppercase font-semibold text-sm pl-0'
                                                                onChange={onChangeStatus}
                                                                value={getSales.params.status}
                                                            >
                                                                <option value="" className='option'>Estatus</option>
                                                                <option value="0" className='option'>Enviada</option>
                                                                <option value="1" className='option'>Aceptada</option>
                                                                <option value="2" className='option'>Rechazada</option>
                                                                <option value="3" className='option'>Cancelada</option>
                                                                <option value="4" className='option'>En proceso</option>
                                                                <option value="5" className='option'>Terminada</option>
                                                            </select>
                                                    </Th>
                                                    <Th className="px-6 min-w-[100px]" >
                                                        <select
                                                            className='multiselect__input bg-tailgreen text-white uppercase font-semibold text-sm pl-0'
                                                            onChange={onChangeStatusPaidToProvider}
                                                            // value={getUsers.params.isActiveFilter}
                                                        >
                                                            <option value="" className='option'>Estatus Pago</option>
                                                            <option value="1" className='option'>Pagado</option>
                                                            <option value="0" className='option'>No pagado</option>
                                                        </select>
                                                    </Th>
                                                    <Th scope="col" className="px-6 py-3">
                                                    </Th>
                                                </tr>
                                            </thead>
                                            <tbody className="">
                                                {getSales.sales.map((sale, i) => (
                                                    <tr key={sale.objectId} className=''>

                                                        <Td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="table-text">{sale.identifier}</div>
                                                        </Td>

                                                        <Td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="flex items-center">
                                                                <div className="flex-shrink-0 h-20 w-20">
                                                                    <img
                                                                        className="h-20 w-20 rounded-full"
                                                                        src={sale?.userClientData?.pictureDat?.fullpath || AvatarPhotodefault}
                                                                        onError={(e) => e.target.src = AvatarPhotodefault}
                                                                        alt="" />
                                                                </div>
                                                                <div className="ml-4">
                                                                    <div className="table-text">
                                                                    {sale?.userClientData?.name}{' '}
                                                                    {sale?.userClientData?.lastNameP}{' '}
                                                                    {sale?.userClientData?.lastNameM}</div>
                                                                </div>
                                                            </div>
                                                        </Td>
                                                        <Td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="table-text">{sale.date}</div>
                                                        </Td>
                                                        <Td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="table-text">{sale?.petData?.petTypeData?.name}</div>
                                                        </Td>
                                                        <Td className="px-6 py-4 ">
                                                            <div className="table-text">{sale?.petData?.name}</div>
                                                        </Td>
                                                        <Td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="table-text">{sale?.serviceData?.serviceTypeData?.name}</div>
                                                        </Td>

                                                        <Td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="table-text">{formatCurrency(sale.price)}</div>
                                                        </Td>
                                                        <Td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="table-text" style={{padding: '4px 13px', background: '#FFFFFF', border: '1px solid #979797',
                                                                borderRadius: '12px', display: 'flex', alignItems: 'center', width: 'fit-content'}}> 
                                                                    <div>
                                                                        <DotsCircleHorizontalIcon className={classNames(
                                                                    sale?.status === 4 || sale?.status === 5 ? 'w-3 h-3 mr-1 fill-tailgreen mb-1' : 
                                                                    sale?.status === 2 || sale?.status === 3 ? 'w-3 h-3 mr-1 fill-tailred mb-1' :
                                                                    'w-3 h-3 mr-1 fill-tailyellow mb-1')}/>
                                                                    </div>
                                                                    <div>{renderAppointmentStatus(sale?.status)}</div>
                                                                </div>
                                                        </Td>
                                                        <Td className="px-6 py-4 whitespace-nowrap">
                                                                <Switch.Group>
                                                                    <div className="flex items-center">
                                                                        <Switch
                                                                            disabled={sale?.isPaidToProvider === 1}
                                                                            checked={sale?.isPaidToProvider === 1}
                                                                            onChange={() => showModalStatusPaid(sale)}
                                                                            className={`${sale?.isPaidToProvider == 1 ? 'bg-green-600' : 'bg-darkred'
                                                                                } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
                                                                        >
                                                                            <span
                                                                                className={`${sale?.isPaidToProvider == 1 ? 'translate-x-6' : 'translate-x-1'
                                                                                    } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                                                                            />
                                                                        </Switch>
                                                                        <p className={
                                                                            classNames(
                                                                                "ml-2",
                                                                                sale?.isPaidToProvider == 1 ? 'text-green-600' : 'text-darkred')
                                                                        }>
                                                                            {sale?.isPaidToProvider == 1 ? 'Pagado' : 'No pagado'}
                                                                        </p>
                                                                    </div>
                                                                </Switch.Group>
                                                            </Td>
                                                        <Td>
                                                            <div className="flex flex-row justify-center items-center">
                                                                <NavLink
                                                                    to={`/historico/`+sale.objectId+`/detalle`}
                                                                    className='w-full flex flex-row items-center justify-center group lg:justify-end'>
                                                                    <div className="text-darkgreen hover:text-tailgreen mt-1">
                                                                        <EyeIcon className='w-6 h-6 mr-5' />
                                                                    </div>
                                                                </NavLink>
                                                                {/* <button
                                                                    onClick={() => showModalDelete(user)}
                                                                    className="text-tailgreen hover:text-mediumgreen mt-1"
                                                                >
                                                                    <TrashIcon className='w-6 h-6' />
                                                                </button> */}
                                                            </div>
                                                        </Td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                        :
                                        <div className='flex justify-center mt-20'>
                                            <p className='h2'>  No hay resultados</p>
                                        </div>
                                }

                                {
                                    (getSales.pagination && getSales.pagination.last_page !== 1) ?
                                        <Pagination paginate={getSales.pagination} onChangue={onChangePage}></Pagination>
                                        : null
                                }
                            </>
                    }
                </div>

            </div>
            <Transition.Root show={openModalStatusPaid} as={Fragment}>
                    <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonModalUpdatePaidRef} onClose={setOpenModalStatusPaid}>
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                                                <ExclamationIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                                            </div>
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                                    Actualizar Estatus
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        ¿Deseas actualizar el estatus del pago al proveedor a pagado?
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-300"
                                            onClick={() => updateStatusPaidToProvider(appointment)}
                                            disabled={updateAppointmentStatusPaidToProvider.loading}
                                        >
                                            Actualizar
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-300"
                                            onClick={() => setOpenModalStatusPaid(false)}
                                            disabled={updateAppointmentStatusPaidToProvider.loading}
                                            ref={cancelButtonModalUpdatePaidRef}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>

        </div>
        
    )
}