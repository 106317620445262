import { BanIcon, CheckIcon, DotsCircleHorizontalIcon, EyeIcon, PencilIcon, PlusIcon, StarIcon, TrashIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import fake from '../../assets/content.json'
import Pagination from '../../components/Pagination'
import SearchInput from '../../components/SearchInput'
import { Table, Td, Th } from '../../components/Table'
import useGetReviews from '../../hooks/reviews/useGetReviews'
import useUpdateReviewStatus from '../../hooks/reviews/useUpdateReviewStatus'
import { classNames } from '../../utils/CssHelpers'
import { useDebounce } from '../../hooks/utils/useDebounce'
import OrderIcon from '../../components/OrderIcon'
import { format, compareAsc } from 'date-fns'


export const ReviewsPage = () => {

    const getReviews = useGetReviews()
    const updateReviewStatus = useUpdateReviewStatus()

    const [search, setSearch] = useState('')
    const fieldSearch = { value: search, onChange: (e) => setSearch(e.target.value) }
    const debouncedSearch = useDebounce(search, 500);

    const onChangeField = (field) => {
        getReviews.setParams({
            ...getReviews.params,
            sortField: field,
            sortDir: getReviews.params.sortDir * -1
        })
    }

    const onChangePage = (p) => {
        getReviews.setParams({
            ...getReviews.params,
            pageNum: (p - 1)
        })
    }

    const onChangeStatus = (e) => {
        const statusFilterAux = e.target.value
        const { statusFilter, ...resp } = getReviews.params
        getReviews.setParams({
            ...resp,
            pageNum: 0,
            ...(statusFilterAux !== '' ? { statusFilter: statusFilterAux } : {}),
        })
    }

    const updateStatus = async (objectId, status) => {

        const resp = await updateReviewStatus.handleUpdateReviewStatus({
            reviewObjectId: objectId,
            status: status
        })
        if (resp)
            getReviews.setParams({ ...getReviews.params, })  /*  */
    }

    useEffect(() => {
        getReviews.handleGetReviews()
        return () => { };
    }, [getReviews.params])

    useEffect(() => {
        getReviews.setParams({
            ...getReviews.params,
            filter: debouncedSearch,
            pageNum: 0
        })
        return () => { };
    }, [debouncedSearch])

    return (

        <div className="-my-2 sm:-mx-6 lg:-mx-8">

            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">

                <div className='flex mb-5 items-center'>

                    <div className='w-2/3 lg:w-1/2'>

                        <SearchInput
                            field={fieldSearch}
                            meta={{ touched: search }}
                            label='Buscar'
                        ></SearchInput>

                    </div>

                </div>

                <div className='grid grid-cols-1 pb-5'>
                    {
                        getReviews.loading ?
                            <div className='flex justify-center mt-20'>
                                <p className='h2'> Cargando...</p>
                            </div>
                            :
                            <>
                                {
                                    (getReviews.reviews && getReviews.reviews.length !== 0) ?
                                        <Table>
                                            <thead className="">
                                                <tr>
                                                    <Th className="px-6 min-w-[100px]">
                                                        <OrderIcon
                                                            field={"reviews.identifier"}
                                                            params={getReviews.params}
                                                            onChange={onChangeField}
                                                        >ID</OrderIcon>
                                                    </Th>
                                                    <Th className="px-6 min-w-[100px]">
                                                        <OrderIcon
                                                            field={"stores.tradeName"}
                                                            params={getReviews.params}
                                                            onChange={onChangeField}
                                                        >Proveedor</OrderIcon>
                                                    </Th>
                                                    <Th className="px-6 min-w-[100px]">
                                                        <OrderIcon
                                                            field={"users.name"}
                                                            params={getReviews.params}
                                                            onChange={onChangeField}
                                                        >Cliente</OrderIcon>
                                                    </Th>
                                                    <Th className="px-6 min-w-[100px]" >
                                                        <OrderIcon
                                                            field={"reviews.created_at"}
                                                            params={getReviews.params}
                                                            onChange={onChangeField}
                                                        >Fecha</OrderIcon>
                                                    </Th>
                                                    <Th className="px-6 min-w-[100px]" >
                                                        <OrderIcon
                                                            field={"reviews.rating"}
                                                            params={getReviews.params}
                                                            onChange={onChangeField}
                                                        >Calificación</OrderIcon>
                                                    </Th>
                                                    <Th className="px-6 min-w-[100px]" >
                                                        <OrderIcon
                                                            field={"reviews.comment"}
                                                            params={getReviews.params}
                                                            onChange={onChangeField}
                                                        >Comentarios</OrderIcon>
                                                    </Th>
                                                    <Th className="px-6 min-w-[100px]" >
                                                        <select
                                                            className='multiselect__input bg-tailgreen text-white uppercase font-semibold text-sm pl-0'
                                                            onChange={onChangeStatus}
                                                            value={getReviews.params.statusFilter}
                                                        >
                                                            <option value="" className='option'>Estatus</option>
                                                            <option value="0" className='option'>En Revisión</option>
                                                            <option value="1" className='option'>Aceptada</option>
                                                            <option value="2" className='option'>Rechazada</option>
                                                        </select>
                                                    </Th>
                                                    <Th scope="col" className="px-6 py-3">
                                                    </Th>
                                                </tr>
                                            </thead>
                                            <tbody className="">
                                                {getReviews.reviews.map((review, i) => (
                                                    <tr key={review.objectId} className=''>

                                                        <Td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="table-text">{review.identifier}</div>
                                                        </Td>
                                                        <Td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="table-text">{review?.storeData?.tradeName}</div>
                                                        </Td>
                                                        <Td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="table-text">{review?.userClientData?.name}{' '}{review?.userClientData?.lastNameP} {' '}{review?.userClientData?.lastNameP}</div>
                                                        </Td>
                                                        <Td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="table-text">{review?.created_at ? format(new Date(review.created_at), 'dd-MM-yyyy') : ' '}</div>
                                                        </Td>
                                                        <Td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="table-text" style={{display: "flex"}}>
                                                                <div><StarIcon className="w-6 h-6 mr-1 text-tailyellow"/></div>
                                                                <div>{review?.rating}</div>
                                                            </div>
                                                        </Td>
                                                        <Td className="px-6 py-4 w-96">
                                                            <div className="table-text">{review?.comment}</div>
                                                        </Td>
                                                        <Td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="table-text" style={{padding: '4px 13px', background: '#FFFFFF', border: '1px solid #979797',
                                                                borderRadius: '12px', display: 'flex', alignItems: 'center', width: 'fit-content'}}> 
                                                                    <div>
                                                                        <DotsCircleHorizontalIcon className={classNames(
                                                                        review?.status === 1 ? 'w-3 h-3 mr-1 fill-tailgreen mb-1' : 
                                                                        review?.status === 2 ? 'w-3 h-3 mr-1 fill-tailred mb-1' :
                                                                        'w-3 h-3 mr-1 fill-tailyellow mb-1')}/>
                                                                        </div>
                                                                        <div className="table-text">{review?.status === 0 ? 'En revisión' : review?.status === 1 ? 'Aceptada' : 'Rechazada' }</div>
                                                                </div>
                                                        </Td>
                                                        <Td>
                                                            <button
                                                                onClick={() => updateStatus(review.objectId, 2)}
                                                                className="text-tailgreen hover:text-mediumgreen mt-1"
                                                            >
                                                                <BanIcon className='w-6 h-6 mr-5'/>
                                                            </button>
                                                            <button
                                                                onClick={() => updateStatus(review.objectId, 1)}
                                                                className=" hover:text-mediumgreen mt-1"
                                                            >
                                                                <CheckIcon className='w-6 h-6 mr-5' />
                                                            </button>
                                                        </Td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                        :
                                        <div className='flex justify-center mt-20'>
                                            <p className='h2'>  No hay resultados</p>
                                        </div>
                                }

                                {
                                    (getReviews.pagination && getReviews.pagination.last_page !== 1) ?
                                        <Pagination paginate={getReviews.pagination} onChangue={onChangePage}></Pagination>
                                        : null
                                }
                            </>
                    }
                </div>

            </div>

        </div>
    )
}