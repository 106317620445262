import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { errorAlert, unauthorizedUser } from '../../utils/AlertToast'
import { api } from '../../utils/ApiMethods'

const useGetUser = () => {
    const { authAction, auth } = useAuth()
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState()

    const handleGetUser = async (objectId) => {
        setLoading(true)
        try {
            const response = await api(auth.token).getPost('getUserById').sendJson({ userObjectId: objectId })
            const data = await response.json()
            setLoading(false)
            if (response.status === 200) {
                setUser(data)
                console.log(data);
                return true
            }
            unauthorizedUser(response.status, authAction, data.message)
        } catch (e) {
            setLoading(false)
            errorAlert()
        }
        return false
    }
    
    return { handleGetUser, loading, user }

}

export default useGetUser