import { useState } from "react"
import { useAuth } from "../../context/AuthContext"
import { errorAlert, successAlert } from "../../utils/AlertToast"
import { api } from "../../utils/ApiMethods"

const useUpdateAppointmentStatusPaidToProvider = () => {

    const { authAction, auth } = useAuth()

    const [loading, setLoading] = useState(false)

    const handleUpdateStatusPaidProvider = async (values) => {

        setLoading(true)

        try {

            const response = await api(auth.token).post('updateAppointmentStatusPaidToProvider').sendForm(values)
            const data = await response.json()

            setLoading(false)

            if (response.status === 200) {

                successAlert('', "Estatus actualizado")
                return true

            }
            else
                errorAlert('', data.message)

        } catch (e) {

            console.log(e)
            setLoading(false)
            errorAlert()

        }

        return false

    }

    return { handleUpdateStatusPaidProvider , loading }

}

export default useUpdateAppointmentStatusPaidToProvider