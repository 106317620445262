import 'core-js/es/promise'
import 'core-js/es/set'
import 'core-js/es/map'
import * as Yup from 'yup'

const CreateProvider = Yup.object().shape({
    tradeName: Yup.string().required('Elemento requerido.'),
    email: Yup.string().email('Correo invalido.').required('Elemento requerido.'),
    businessName: Yup.string().required('Elemento requerido.'),
    phoneNumber: Yup.string().required('Elemento requerido.'),
})

const Legal = Yup.object().shape({
    legalRepresentativeName: Yup.string().required('Elemento requerido.'),
    legalRepresentativePhoneNumber: Yup.string().required('Elemento requerido.'),
    constitutionDate: Yup.string().required('Elemento requerido.'),
    legalStructure: Yup.string().required('Elemento requerido.'),
    legalStreet: Yup.string().required('Elemento requerido.'),
    legalExteriorNumber: Yup.string().required('Elemento requerido.'),
    legalStructure: Yup.string().required('Elemento requerido.'),
    legalNeighborhood: Yup.string().required('Elemento requerido.'),
    legalMunicipality: Yup.string().required('Elemento requerido.'),
    legalCity: Yup.string().required('Elemento requerido.'),
    legalState: Yup.string().required('Elemento requerido.'),
    legalCountry: Yup.string().required('Elemento requerido.'),
    legalPostalCode: Yup.string().required('Elemento requerido.'),
    longitude: Yup.string().required('Elemento requerido.'),
    latitude: Yup.string().required('Elemento requerido.'),
})

const Commercial = Yup.object().shape({
    commercialStreet: Yup.string().required('Elemento requerido.'),
    commercialExteriorNumber: Yup.string().required('Elemento requerido.'),
    commercialNeighborhood: Yup.string().required('Elemento requerido.'),
    commercialMunicipality: Yup.string().required('Elemento requerido.'),
    commercialCity: Yup.string().required('Elemento requerido.'),
    commercialState: Yup.string().required('Elemento requerido.'),
    commercialCountry: Yup.string().required('Elemento requerido.'),
    commercialPostalCode: Yup.string().required('Elemento requerido.'),
    longitude: Yup.string().required('Elemento requerido.'),
    latitude: Yup.string().required('Elemento requerido.'),
})

export { CreateProvider, Legal, Commercial }