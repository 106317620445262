import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { errorAlert, unauthorizedUser } from '../../utils/AlertToast'
import { api } from '../../utils/ApiMethods'

const useGetStore = () => {
    const { authAction, auth } = useAuth()
    const [loading, setLoading] = useState(false)
    const [store, setStore] = useState()

    const handleGetStore = async (objectId) => {
        setLoading(true)
        try {
            const response = await api(auth.token).getPost('getStoreById').sendJson({ storeObjectId: objectId })
            const data = await response.json()
            setLoading(false)
            if (response.status === 200) {
                setStore(data)
                console.log(data);
                return true
            }
            unauthorizedUser(response.status, authAction, data.message)
        } catch (e) {
            setLoading(false)
            errorAlert()
        }
        return false
    }
    
    return { handleGetStore, loading, store }

}

export default useGetStore