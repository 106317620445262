import { useState } from "react"
import { errorAlert, successAlert } from "../../utils/AlertToast"
import { api } from "../../utils/ApiMethods"

const useConfirmAccount = () => {

    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState()

    const handleConfirmAccount = async (code) => {

        setLoading(true)

        try {
            const response = await api().post('validateConfirmationCode').sendJson({confirmationCode: code})
            const data = await response.json()

            setLoading(false)
            if (response.status === 200) {
                successAlert('', "Tu cuenta se ha verfificado con éxito.")
                setStatus(true)
                return true
            }

            else
                errorAlert('', data.message)
            setStatus(false)

        } catch (e) {
            setStatus(false)
            setLoading(false)
            errorAlert()
            console.log(e)


        }
        return false
    }

    return { handleConfirmAccount, loading, status }
}

export default useConfirmAccount