import { AcademicCapIcon } from '@heroicons/react/solid';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FieldInput from '../../components/FieldInput';
import VendorMaps from '../../components/VendorMaps';
import useGetAllProviders from '../../hooks/providers/useGetAllProviders';
import useCreateOrUpdateProvider from '../../hooks/stores/useCreateOrUpdateProvider';
import useCreateStore from '../../hooks/stores/useCreateStore';
import useGetStore from '../../hooks/stores/useGetStore';
import useUpdateCommercialAddress from '../../hooks/stores/useUpdateCommercialAddress';
import useUpdateLegalInfo from '../../hooks/stores/useUpdateLegalInfo';
import { Commercial, CreateProvider, Legal } from '../../schemas/CreatedStoreSchemas';
import { classNames } from '../../utils/CssHelpers';
import Facebook from './../../assets/images/svg/app/Icono_Facebook.svg'
import Instagram from './../../assets/images/svg/app/Icono_Instagram.svg'
import Tiktok from './../../assets/images/svg/app/Icono_Tiktok.svg'
import Twitter from './../../assets/images/svg/app/Icono_Twitter.svg'
import { useParams } from 'react-router-dom'

const estructuraslegales = ["Sociedad anonima", "Sociedad", "Empresa conjunta", "Franquicia", "Otro"]

const check = (isSucursal, val, alt) => (isSucursal && val) ? val : alt

export const VendorUpdatePage = () => {

    const navigate = useNavigate()
    const { id } = useParams()
 
    const getStore = useGetStore()
    const getAllProviders = useGetAllProviders()
    const createStore = useCreateStore()
    const createOrUpdateProvider = useCreateOrUpdateProvider()
    const updateLegalInfo = useUpdateLegalInfo()
    const updateCommercialAddress = useUpdateCommercialAddress()

    const [section, setSection] = useState(1)
    const [nextSection, setNextSection] = useState(2)
    const [useAddress, setUseAddress] = useState(false)
    const [adrees, setAdrees] = useState({})
    /* Cambiar provedor  */
    const [provider, setProvider] = useState(null)
    const onChangeProvider = (e) => {
        if (e?.target?.value && e?.target?.value !== "")
            setProvider(getAllProviders.providers.find(p => p.objectId === e.target.value))
        else
            setProvider(null)
    }
    /* Create provedor  */
    const [isSucursal, setIsSucursal] = useState(false)
    const [idProvider, setIdProvider] = useState('')
    const onSubmitcreateProvider = async (values) => {
        if (isSucursal) {
            const resp = await createStore.handleCreateStore({
                providerObjectId: provider.objectId,
                storeObjectId: id,
                ...values
            }, "La información ha sido guardada")
            if (resp) {
                setNextSection(2)
                setSection(2)
            }
        }
        else {
            const resp = await createOrUpdateProvider.handleCreateOrUpdateProvider({
                storeObjectId: id,
                ...values
            }, "La información ha sido guardada")
            if (resp) {
                setNextSection(2)
                setSection(2)
            }
        }
    }

    /* Update Legar */
    const onSubmitUpdateLegalInfo = async (values) => {
        console.log(values, isSucursal);
        const resp = await updateLegalInfo.handleUpdateLegalInfo({
            storeObjectId: id,
            ...values
        }, "La información ha sido guardada")
        if (resp) {
            setAdrees(values)
            setNextSection(3)
        }
    }

    /* Update Commercial */
    const onSubmitUpdateCommercialAddress = async (values) => {
        const resp = await updateCommercialAddress.handleUpdateCommercialAddress({
            useLegalAddress: useAddress ? 1 : 0,
            storeObjectId: id,
            ...values
        }, "La información ha sido guardada")
        if (resp) {
            //navigate("/proveedores")
            setNextSection(4)
        }
    }

    useEffect(() => {
        getStore.handleGetStore(id)
        return (() => {})
    }, [])

    return (
        <div className="flex flex-col">
            <div className="flex justify-center mt-20">
                <div className=''>
                    <div className='flex flex-row items-center'>
                        <div className='w-14'></div>
                        <div className={classNames(
                            section === 1 ? 'bg-tailgreen' : 'bg-mediumgray',
                            'w-10 h-10 rounded-full flex justify-center items-center  mx-3'
                        )}> <small className='text-white text-2xl font-bold'>1</small></div>
                        <div className='w-14 h-1 bg-mediumgray'></div>
                    </div>
                    <div className='flex justify-center h3 mt-4'>Datos generales</div>
                </div>
                <div className=''>
                    <div className='flex flex-row items-center'>
                        <div className='w-14 h-1 bg-mediumgray'></div>
                        <div className={classNames(
                            section === 2 ? 'bg-tailgreen' : 'bg-mediumgray',
                            'w-10 h-10 rounded-full flex justify-center items-center  mx-3'
                        )}> <small className='text-white text-2xl font-bold'>2</small></div>
                        <div className='w-14 h-1 bg-mediumgray'></div>
                    </div>
                    <div className='flex justify-center h3 mt-4'>Información legal</div>
                </div>
                <div className=''>
                    <div className='flex flex-row items-center'>
                        <div className='w-14 h-1 bg-mediumgray'></div>
                        <div className={classNames(
                            section === 3 ? 'bg-tailgreen' : 'bg-mediumgray',
                            'w-10 h-10 rounded-full flex justify-center items-center  mx-3'
                        )}> <small className='text-white text-2xl font-bold'>3</small></div>
                        <div className='w-14'></div>
                    </div>
                    <div className='flex justify-center h3 mt-4'>Dirección comercial</div>
                </div>
            </div>
            {
                section === 1 ?
                    <Formik
                        enableReinitialize
                        initialValues={{
                            "tradeName": getStore?.store?.tradeName ?? '',
                            "businessName": getStore?.store?.providerData?.businessName ?? '',
                            "phoneNumber": getStore?.store?.userProviderData?.phoneNumber ?? '',
                            "email": getStore?.store?.userProviderData?.email ?? '',
                            "urlWebSite": getStore?.store?.urlWebSite ?? '',
                            "facebook": getStore?.store?.facebook ?? '',
                            "instagram": getStore?.store?.instagram ?? '',
                            "twitter": getStore?.store?.twitter ?? '',
                            "tiktok": getStore?.store?.tiktok ?? '',
                        }}
                        onSubmit={onSubmitcreateProvider}
                        validationSchema={CreateProvider}
                    >
                        {({ values, errors }) => (
                            <Form>
                                <div className='grid grid-cols-1 mt-20 md:grid-cols-2 gap-10'>
                                    <div className='col-span-1'>
                                        <h2 className='h2'>Datos generales</h2>

                                        <div className='flex items-center mt-5'>
                                            <input
                                                type='checkbox'
                                                className='w-8 h-8 form-checkbox rounded-full text-tailgreen mr-5 border-gray-300 hover:ring-tailgreen focus:ring-tailgreen'
                                                checked={isSucursal}
                                                onChange={(e) => setIsSucursal(!isSucursal)}
                                            /><small className='h3'>¿Es sucursal?</small>
                                        </div>

                                        <select
                                            className={classNames(
                                                "w-full mt-5 inp-small inp-small_empty bg-semigray form-select",
                                                !isSucursal ?
                                                    "ring-gray-300 border-gray-300 focus:border-gray-300 focus:ring-gray-300"
                                                    :
                                                    "ring-tailgreen border-tailgreen focus:border-tailgreen focus:ring-tailgreen"
                                            )}
                                            disabled={!isSucursal}
                                            onChange={onChangeProvider}
                                        >
                                            <option value="">Seleccione la matriz</option>
                                            {
                                                getAllProviders.providers.map((p) => (
                                                    <option value={p.objectId} key={p.objectId}>{p.businessName}</option>
                                                ))
                                            }
                                        </select>

                                        <Field
                                            name="tradeName"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={isSucursal}
                                                    className={"mt-5"}
                                                    label="Razón social"
                                                    type="text"
                                                ></FieldInput>
                                            }
                                        </Field>

                                        <Field
                                            name="businessName"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={false}
                                                    className={"mt-5"}
                                                    label="Nombre comercial"
                                                    type="text"
                                                ></FieldInput>
                                            }
                                        </Field>

                                        <Field
                                            name="phoneNumber"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={false}
                                                    className={"mt-5"}
                                                    label="Teléfono"
                                                    type="number"
                                                ></FieldInput>
                                            }
                                        </Field>

                                        <Field
                                            name="email"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={false}
                                                    className={"mt-5"}
                                                    label="Correo electrónico"
                                                    type="text"
                                                ></FieldInput>
                                            }
                                        </Field>

                                        <Field
                                            name="urlWebSite"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={false}
                                                    className={"mt-5"}
                                                    label="Sitio web"
                                                    type="text"
                                                ></FieldInput>
                                            }
                                        </Field>

                                    </div>
                                    <div className='col-span-1'>

                                        <h2 className='h2'>Redes sociales</h2>
                                        <div className='flex flex-row mt-5 items-center'>

                                            <div className='mr-2'>
                                                <img className='w-16 h-16' src={Facebook}></img>
                                            </div>

                                            <Field
                                                name="facebook"
                                            >
                                                {
                                                    (atrs) => <FieldInput
                                                        {...atrs}
                                                        disabled={false}
                                                        className={"w-full"}
                                                        label="Liga a Facebook"
                                                        type="text"
                                                    ></FieldInput>
                                                }
                                            </Field>
                                        </div>

                                        <div className='flex flex-row mt-5 items-center'>

                                            <div className='mr-2'>
                                                <img className='w-16 h-16' src={Instagram}></img>
                                            </div>

                                            <Field
                                                name="instagram"
                                            >
                                                {
                                                    (atrs) => <FieldInput
                                                        {...atrs}
                                                        disabled={false}
                                                        className={"w-full"}
                                                        label="Liga a Instagram"
                                                        type="text"
                                                    ></FieldInput>
                                                }
                                            </Field>
                                        </div>

                                        <div className='flex flex-row mt-5 items-center'>

                                            <div className='mr-2'>
                                                <img className='w-16 h-16' src={Twitter}></img>
                                            </div>

                                            <Field
                                                name="twitter"
                                            >
                                                {
                                                    (atrs) => <FieldInput
                                                        {...atrs}
                                                        disabled={false}
                                                        className={"w-full"}
                                                        label="Liga a Twitter"
                                                        type="text"
                                                    ></FieldInput>
                                                }
                                            </Field>
                                        </div>

                                        <div className='flex flex-row mt-5 items-center'>

                                            <div className='mr-2'>
                                                <img className='w-16 h-16' src={Tiktok} ></img>
                                            </div>

                                            <Field
                                                name="tiktok"
                                            >
                                                {
                                                    (atrs) => <FieldInput
                                                        {...atrs}
                                                        disabled={false}
                                                        className={"w-full"}
                                                        label="Liga a TikTok"
                                                        type="text"
                                                    ></FieldInput>
                                                }
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex my-20 justify-end border-t-2 border-mediumgray py-10 space-x-5'>
                                    <button className='btn-secondary btn-medium w-[250px]' onClick={() => navigate("/proveedores")}>
                                        Cancelar
                                    </button>
                                    <button className='btn-prymary btn-small w-[250px]' type="submit">
                                        Siguiente
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    : null
            }

            {
                section === 2 ?
                    <Formik
                        enableReinitialize
                        initialValues={{
                            "legalRepresentativeName": check(isSucursal, provider?.legalRepresentativeName, getStore?.store?.providerData?.legalRepresentativeName),
                            "legalRepresentativePhoneNumber": check(isSucursal, provider?.legalRepresentativePhoneNumber, getStore?.store?.providerData?.legalRepresentativePhoneNumber),
                            "constitutionDate": check(isSucursal, provider?.constitutionDate ? (new Date(provider?.constitutionDate)).toISOString().slice(0, 19) : "", (new Date(getStore?.store?.providerData?.constitutionDate)).toISOString().slice(0, 19)),
                            "legalStructure": check(isSucursal, provider?.legalStructure, getStore?.store?.providerData?.legalStructure),
                            "legalStreet": check(isSucursal, provider?.legalAddress?.street, getStore?.store?.providerData?.legalAddress?.street),
                            "legalInteriorNumber": check(isSucursal, provider?.legalAddress?.interiorNumber, getStore?.store?.providerData?.legalAddress?.interiorNumber),
                            "legalExteriorNumber": check(isSucursal, provider?.legalAddress?.exteriorNumber, getStore?.store?.providerData?.legalAddress?.exteriorNumber),
                            "legalNeighborhood": check(isSucursal, provider?.legalAddress?.neighborhood, getStore?.store?.providerData?.legalAddress?.neighborhood),
                            "legalMunicipality": check(isSucursal, provider?.legalAddress?.municipality, getStore?.store?.providerData?.legalAddress?.municipality),
                            "legalCity": check(isSucursal, provider?.legalAddress?.city, getStore?.store?.providerData?.legalAddress?.city),
                            "legalState": check(isSucursal, provider?.legalAddress?.state, getStore?.store?.providerData?.legalAddress?.state),
                            "legalCountry": check(isSucursal, provider?.legalAddress?.country, getStore?.store?.providerData?.legalAddress?.country),
                            "legalPostalCode": check(isSucursal, provider?.legalAddress?.postalCode, getStore?.store?.providerData?.legalAddress?.postalCode),
                            "latitude": check(isSucursal, provider?.legalAddress?.latitude, getStore?.store?.providerData?.legalAddress?.latitude ?? "19.409342561076112"),
                            "longitude": check(isSucursal, provider?.legalAddress?.longitude, getStore?.store?.providerData?.legalAddress?.longitude ?? "-99.1269647875"),
                            "institutionName": check(isSucursal, provider?.bankInfo?.institutionName, getStore?.store?.providerData?.bankInfo?.institutionName),
                            "address": check(isSucursal, provider?.bankInfo?.address, getStore?.store?.providerData?.bankInfo?.address),
                            "beneficiaryName": check(isSucursal, provider?.bankInfo?.beneficiaryName, getStore?.store?.providerData?.bankInfo?.beneficiaryName),
                            "storeNumber": check(isSucursal, provider?.bankInfo?.storeNumber, getStore?.store?.providerData?.bankInfo?.storeNumber),
                            "accountNumber": check(isSucursal, provider?.bankInfo?.accountNumber, getStore?.store?.providerData?.bankInfo?.accountNumber),
                            "accountCLABE": check(isSucursal, provider?.bankInfo?.accountCLABE, getStore?.store?.providerData?.bankInfo?.accountCLABE),
                        }}
                        validationSchema={Legal}
                        onSubmit={onSubmitUpdateLegalInfo}
                    >
                        {({ values, errors, setFieldValue }) => (
                            <Form>
                                <div className='grid grid-cols-2 gap-20 mt-20'>
                                    <div className='col-span-1'>
                                        <h2 className='h2'>Información legal</h2>

                                        <Field
                                            name="legalRepresentativeName"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={isSucursal}
                                                    className={"mt-5"}
                                                    label="Representante legal"
                                                    type="text"
                                                ></FieldInput>
                                            }
                                        </Field>

                                        <Field
                                            name="legalRepresentativePhoneNumber"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={isSucursal}
                                                    className={"mt-5"}
                                                    label="Teléfono de representante legal"
                                                    type="number"
                                                ></FieldInput>
                                            }
                                        </Field>

                                        <Field
                                            name="constitutionDate"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={isSucursal}
                                                    className={"mt-5"}
                                                    label="Fecha de constitución"
                                                    type="datetime-local"
                                                ></FieldInput>
                                            }
                                        </Field>
                                        <h2 className='h2 mt-10'>Estructura legal</h2>
                                        <div className="flex flex-row flex-wrap font-medium text-[15px] cursor-pointer justify-between">
                                            {estructuraslegales.map((e, i) => (
                                                <div key={"el" + i} className={classNames(
                                                    "rounded-md py-3 mr-3 mt-3 w-48 text-center",
                                                    values.legalStructure !== e ?
                                                        "bg-gray-300 text-[#919191]" :
                                                        "bg-tailgreen text-white",
                                                    isSucursal ? "" : "hover:bg-tailgreen hover:text-white"
                                                )}
                                                    onClick={isSucursal ? () => { } : () => setFieldValue("legalStructure", e)}
                                                >
                                                    {e}
                                                </div>
                                            ))}
                                        </div>
                                        <h2 className='h2 mt-10'>Información bancaria</h2>
                                        <Field
                                            name="institutionName"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={isSucursal}
                                                    className={"mt-5"}
                                                    label="Nombre de la institución"
                                                    type="text"
                                                ></FieldInput>
                                            }
                                        </Field>
                                        <Field
                                            name="address"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={isSucursal}
                                                    className={"mt-5"}
                                                    label="Dirección"
                                                    type="text"
                                                ></FieldInput>
                                            }
                                        </Field>
                                        <Field
                                            name="beneficiaryName"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={isSucursal}
                                                    className={"mt-5"}
                                                    label="Nombre de beneficiario"
                                                    type="text"
                                                ></FieldInput>
                                            }
                                        </Field>
                                        <Field
                                            name="storeNumber"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={isSucursal}
                                                    className={"mt-5"}
                                                    label="Número de sucursal"
                                                    type="number"
                                                ></FieldInput>
                                            }
                                        </Field>
                                        <Field
                                            name="accountNumber"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={isSucursal}
                                                    className={"mt-5"}
                                                    label="Número de cuenta"
                                                    type="number"
                                                ></FieldInput>
                                            }
                                        </Field>
                                        <Field
                                            name="accountCLABE"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={isSucursal}
                                                    className={"mt-5"}
                                                    label="Cuenta CLABE"
                                                    type="text"
                                                ></FieldInput>
                                            }
                                        </Field>
                                    </div>
                                    <div className='col-span-1'>
                                        <h2 className='h2'>Dirección legal</h2>
                                        <VendorMaps
                                            center={{ lat: values.latitude, lng: values.longitude }}
                                            setFieldValue={setFieldValue}
                                            disabled={isSucursal}
                                            type="legal"></VendorMaps>

                                        <Field
                                            name="legalStreet"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={isSucursal}
                                                    className={"mt-5"}
                                                    label="Calle"
                                                    type="text"
                                                ></FieldInput>
                                            }
                                        </Field>
                                        <div className='flex flex-row space-x-5'>

                                            <Field
                                                name="legalExteriorNumber"
                                            >
                                                {
                                                    (atrs) => <FieldInput
                                                        {...atrs}
                                                        disabled={isSucursal}
                                                        className={"mt-5 w-full"}
                                                        label="Número exterior"
                                                        type="number"
                                                    ></FieldInput>
                                                }
                                            </Field>

                                            <Field
                                                name="legalInteriorNumber"
                                            >
                                                {
                                                    (atrs) => <FieldInput
                                                        {...atrs}
                                                        disabled={isSucursal}
                                                        className={"mt-5 w-full"}
                                                        label="Número interior"
                                                        type="number"
                                                    ></FieldInput>
                                                }
                                            </Field>
                                        </div>

                                        <Field
                                            name="legalNeighborhood"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={isSucursal}
                                                    className={"mt-5"}
                                                    label="Colonia"
                                                    type="text"
                                                ></FieldInput>
                                            }
                                        </Field>

                                        <Field
                                            name="legalPostalCode"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={isSucursal}
                                                    className={"mt-5"}
                                                    label="Código postal"
                                                    type="number"
                                                ></FieldInput>
                                            }
                                        </Field>

                                        <Field
                                            name="legalMunicipality"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={isSucursal}
                                                    className={"mt-5"}
                                                    label="Municipio"
                                                    type="text"
                                                ></FieldInput>
                                            }
                                        </Field>

                                        <Field
                                            name="legalState"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={isSucursal}
                                                    className={"mt-5"}
                                                    label="Estado"
                                                    type="text"
                                                ></FieldInput>
                                            }
                                        </Field>

                                        <Field
                                            name="legalCity"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={isSucursal}
                                                    className={"mt-5"}
                                                    label="Ciudad"
                                                    type="text"
                                                ></FieldInput>
                                            }
                                        </Field>

                                        <Field
                                            name="legalCountry"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={isSucursal}
                                                    className={"mt-5"}
                                                    label="País"
                                                    type="text"
                                                ></FieldInput>
                                            }
                                        </Field>

                                    </div>
                                </div>
                                <div className='flex my-20 justify-end border-t-2 border-mediumgray py-10 space-x-5'>
                                    <button className='btn-secondary btn-medium w-[250px]' onClick={() => navigate("/proveedores")} type="button">
                                        Cancelar
                                    </button>
                                    <button className='btn-prymary btn-small w-[250px]' type="submit" disabled={updateLegalInfo.loading}>
                                        Guardar
                                    </button>
                                    {
                                        nextSection === section ? null : (
                                            <button className='btn-prymary btn-small w-[250px]' onClick={() => setSection(3)} type="button">
                                                Siguiente
                                            </button>
                                        )
                                    }
                                </div>
                            </Form>
                        )}
                    </Formik>
                    : null
            }

            {
                section === 3 ?
                    <Formik
                        enableReinitialize
                        initialValues={{
                            "commercialStreet": check(useAddress, getStore?.store?.providerData?.legalAddress?.street, getStore?.store?.commercialAddress?.street),
                            "commercialExteriorNumber": check(useAddress, getStore?.store?.providerData?.legalAddress?.xxteriorNumber, getStore?.store?.commercialAddress?.exteriorNumber),
                            "commercialInteriorNumber": check(useAddress, getStore?.store?.providerData?.legalAddress?.interiorNumber, getStore?.store?.commercialAddress?.interiorNumber),
                            "commercialNeighborhood": check(useAddress, getStore?.store?.providerData?.legalAddress?.neighborhood, getStore?.store?.commercialAddress?.neighborhood),
                            "commercialMunicipality": check(useAddress, getStore?.store?.providerData?.legalAddress?.municipality, getStore?.store?.commercialAddress?.municipality),
                            "commercialCity": check(useAddress, getStore?.store?.providerData?.legalAddress?.city, getStore?.store?.commercialAddress?.city),
                            "commercialState": check(useAddress, getStore?.store?.providerData?.legalAddress?.state, getStore?.store?.commercialAddress?.state),
                            "commercialCountry": check(useAddress, getStore?.store?.providerData?.legalAddress?.country, getStore?.store?.commercialAddress?.country),
                            "commercialPostalCode": check(useAddress, getStore?.store?.providerData?.legalAddress?.postalCode, getStore?.store?.commercialAddress?.postalCode),
                            "latitude": check(useAddress, getStore?.store?.providerData?.legalAddress?.latitude, getStore?.store?.commercialAddress?.latitude),
                            "longitude": check(useAddress, getStore?.store?.providerData?.legalAddress?.longitude, getStore?.store?.commercialAddress?.longitude),
                        }}
                        validationSchema={Commercial}
                        onSubmit={onSubmitUpdateCommercialAddress}
                    >

                        {({ values, errors, setFieldValue }) => {
                            console.log(errors);
                            return (
                                <Form>
                                    <div className='grid grid-cols-2 gap-20 mt-20'>
                                        <div className='col-span-1'>
                                            <h2 className='h2'>Dirección comercial</h2>

                                            <div className='flex items-center mt-5'>
                                                <input
                                                    type='checkbox'
                                                    className='form-checkbox w-8 h-8 rounded-full text-tailgreen mr-5 border-gray-300 hover:ring-tailgreen focus:ring-tailgreen'
                                                    checked={useAddress}
                                                    onChange={(e) => setUseAddress(!useAddress)}
                                                /><small className='h3'>Usar dirección legal</small>
                                            </div>

                                            <Field
                                                name="commercialStreet"
                                            >
                                                {
                                                    (atrs) => <FieldInput
                                                        {...atrs}
                                                        disabled={useAddress}
                                                        className={"mt-5"}
                                                        label="Calle"
                                                        type="text"
                                                    ></FieldInput>
                                                }
                                            </Field>
                                            <div className='flex flex-row space-x-5'>

                                                <Field
                                                    name="commercialExteriorNumber"
                                                >
                                                    {
                                                        (atrs) => <FieldInput
                                                            {...atrs}
                                                            disabled={useAddress}
                                                            className={"mt-5 w-full"}
                                                            label="Número exterior"
                                                            type="number"
                                                        ></FieldInput>
                                                    }
                                                </Field>

                                                <Field
                                                    name="commercialInteriorNumber"
                                                >
                                                    {
                                                        (atrs) => <FieldInput
                                                            {...atrs}
                                                            disabled={useAddress}
                                                            className={"mt-5 w-full"}
                                                            label="Número interior"
                                                            type="number"
                                                        ></FieldInput>
                                                    }
                                                </Field>
                                            </div>

                                            <Field
                                                name="commercialNeighborhood"
                                            >
                                                {
                                                    (atrs) => <FieldInput
                                                        {...atrs}
                                                        disabled={useAddress}
                                                        className={"mt-5"}
                                                        label="Colonia"
                                                        type="text"
                                                    ></FieldInput>
                                                }
                                            </Field>

                                            <Field
                                                name="commercialPostalCode"
                                            >
                                                {
                                                    (atrs) => <FieldInput
                                                        {...atrs}
                                                        disabled={useAddress}
                                                        className={"mt-5"}
                                                        label="Código postal"
                                                        type="number"
                                                    ></FieldInput>
                                                }
                                            </Field>

                                            <Field
                                                name="commercialMunicipality"
                                            >
                                                {
                                                    (atrs) => <FieldInput
                                                        {...atrs}
                                                        disabled={useAddress}
                                                        className={"mt-5"}
                                                        label="Municipio"
                                                        type="text"
                                                    ></FieldInput>
                                                }
                                            </Field>

                                            <Field
                                                name="commercialState"
                                            >
                                                {
                                                    (atrs) => <FieldInput
                                                        {...atrs}
                                                        disabled={useAddress}
                                                        className={"mt-5"}
                                                        label="Estado"
                                                        type="text"
                                                    ></FieldInput>
                                                }
                                            </Field>

                                            <Field
                                                name="commercialCity"
                                            >
                                                {
                                                    (atrs) => <FieldInput
                                                        {...atrs}
                                                        disabled={useAddress}
                                                        className={"mt-5"}
                                                        label="Ciudad"
                                                        type="text"
                                                    ></FieldInput>
                                                }
                                            </Field>

                                            <Field
                                                name="commercialCountry"
                                            >
                                                {
                                                    (atrs) => <FieldInput
                                                        {...atrs}
                                                        disabled={useAddress}
                                                        className={"mt-5"}
                                                        label="País"
                                                        type="text"
                                                    ></FieldInput>
                                                }
                                            </Field>

                                        </div>
                                        <div className='col-span-1'>

                                            <h2 className='h2'>Ubicación de la sucursal</h2>

                                            <VendorMaps
                                                center={{ lat: values.latitude, lng: values.longitude }}
                                                setFieldValue={setFieldValue}
                                                disabled={useAddress}
                                                type="commercial"></VendorMaps>

                                        </div>
                                    </div>
                                    <div className='flex my-20 justify-end border-t-2 border-mediumgray py-10 space-x-5'>
                                        <button className='btn-secondary btn-medium w-[250px]' onClick={() => navigate("/proveedores")}>
                                            Cancelar
                                        </button>

                                        <button className='btn-prymary btn-small w-[250px]' type="submit" disabled={updateCommercialAddress.loading}>
                                            Guardar
                                        </button>
                                        {
                                            nextSection === section ? null : (
                                                <button className='btn-prymary btn-small w-[250px]' onClick={() => navigate("/proveedores")} type="button">
                                                    Finalizar
                                                </button>
                                            )
                                        }
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                    : null
            }

        </div >
    )
}