import { useState } from "react"
import { useAuth } from "../../context/AuthContext"
import { errorAlert, successAlert } from "../../utils/AlertToast"
import { api } from "../../utils/ApiMethods"

const useUpdateLegalInfo = () => {

    const { authAction, auth } = useAuth()

    const [loading, setLoading] = useState(false)

    const handleUpdateLegalInfo = async (values, msg = null) => {

        setLoading(true)

        try {

            const response = await api(auth.token).post('updateLegalInfo').sendForm({ ...values })
            const data = await response.json()

            setLoading(false)
            if (response.status === 200) {
                successAlert('', msg ?? data.message)
                return data
            }
            else
                errorAlert('', data.message)

        } catch (e) {

            console.log(e)
            setLoading(false)
            errorAlert()

        }

        return false

    }

    return { handleUpdateLegalInfo, loading }
}

export default useUpdateLegalInfo