import { SearchIcon } from '@heroicons/react/solid';
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';
import React, { useEffect, useRef, useState } from 'react';
import { classNames } from '../utils/CssHelpers';
import { getAddressObject } from '../utils/UtilsMaps';

const getInfoPosition = (latLng, showResults) => {
    if (window?.google?.maps?.Geocoder) {
        var geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ 'latLng': latLng }, function (results, status) {
            if (status === window.google.maps.GeocoderStatus.OK) {
                showResults(results);
            }
        })
    }
    return null;
}

const centerInit = {
    lat: 19.4326077,
    lng: -99.133208
};

const lib = ['places'];

const fn = (...v) => { console.log(v) }

export default function VendorMaps({ setFieldValue = fn, center = centerInit, disabled = false, type }) {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_API_API_MAPS ?? '',
        libraries: lib
    })
    const inputRef = useRef();
    const [positionMarker, setPositionMarker] = useState(centerInit)
    const [centerMap, setCenterMap] = useState(centerInit)

    useEffect(() => {
        console.log(center);
        updatePosition(parseFloat(center.lat), parseFloat(center.lng))
        return () => { };
    }, [center.lat, center.lng])
    const [searchBox, setSearchBox] = useState(null)

    const updatePosition = (lat, lng) => {
        setCenterMap({ lat, lng })
        setPositionMarker({ lat, lng });
    }
    const onPlacesChanged = () => {
        const places = searchBox.getPlaces()
        const latLng = places[0].geometry.location
        updatePosition(latLng.lat(), latLng.lng())
        updateInfo(places, latLng.lat(), latLng.lng())
    };
    const onSBLoad = ref => setSearchBox(ref)
    const onMarkerDragEnd = (coord) => {
        const { latLng } = coord
        getInfoPosition(latLng, (places) => {
            if (inputRef && places)
                if (places[0]?.formatted_address)
                    inputRef.current.value = places[0].formatted_address
            updateInfo(places, latLng.lat(), latLng.lng())
            updatePosition(latLng.lat(), latLng.lng())
        })
    }

    const updateInfoLegal = (address, lat, lng) => {
        setFieldValue('legalStreett', address.street ?? '')
        setFieldValue('legalInteriorNumber', address.street_number ?? '')
        setFieldValue('legalExteriorNumber', address.street_number ?? '')
        setFieldValue('legalMunicipality', address.region ?? '')
        setFieldValue('legalCity', address.city ?? '')
        setFieldValue('legalCountry', address.country ?? '')
        setFieldValue('legalPostalCode', address.postal_code ?? '')
        setFieldValue('legalNeighborhood', address.neighborhood ?? '')
        setFieldValue('longitude', lng ?? '')
        setFieldValue('latitude', lat ?? '')
    }
    const updateInfoCommercial = (address, lat, lng) => {
        setFieldValue('commercialStreett', address.street ?? '')
        setFieldValue('commercialInteriorNumber', address.street_number ?? '')
        setFieldValue('commercialExteriorNumber', address.street_number ?? '')
        setFieldValue('commercialMunicipality', address.region ?? '')
        setFieldValue('commercialCity', address.city ?? '')
        setFieldValue('commercialCountry', address.country ?? '')
        setFieldValue('commercialPostalCode', address.postal_code ?? '')
        setFieldValue('commercialNeighborhood', address.neighborhood ?? '')
        setFieldValue('longitude', lng ?? '')
        setFieldValue('latitude', lat ?? '')
    }
    const updateInfo = (places, lat, lng) => {
        if (places && places.length > 0) {
            let address = getAddressObject(places[0].address_components)
            if (type === "commercial")
                updateInfoCommercial(address, lat, lng)
            if (type === "legal")
                updateInfoLegal(address, lat, lng)
        }
    }

    return !isLoaded ?
        <div>
            Cargando...
        </div> :
        (
            <>
                <StandaloneSearchBox
                    onPlacesChanged={onPlacesChanged}
                    onLoad={onSBLoad}
                >
                    <div className='relative my-5'>
                        <input
                            disabled={disabled}
                            type="text"
                            placeholder="Buscar"
                            ref={inputRef}
                            className={classNames(
                                "w-full inp-small inp-small_empty text-extradarkgray px-8",
                                !disabled ?
                                    "ring-tailgreen border-tailgreen focus:border-tailgreen focus:ring-tailgreen"
                                    :
                                    "ring-gray-300 border-gray-300 focus:border-gray-300 focus:ring-gray-300"
                            )}
                        />

                        <span className={classNames(
                            "absolute inset-y-0 left-0 flex items-center pl-2",
                            !disabled ? "text-tailgreen" : "text-extradarkgray")}>
                            <SearchIcon className='w-5 h-5' />
                        </span>
                    </div>
                </StandaloneSearchBox>
                <GoogleMap
                    mapContainerStyle={{
                        width: '100%',
                        height: '500px',
                        borderRadius: '25px'
                    }}
                    center={centerMap}
                    zoom={10}
                >
                    <Marker
                        position={positionMarker}
                        draggable={!disabled}
                        onDragEnd={onMarkerDragEnd}
                    />
                </GoogleMap>
            </>
        )
}