import React, { useEffect } from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import useGetStore from '../../hooks/stores/useGetStore'
import FBIcon from '../../assets/icons/png/fb-icon.png'
import IGIcon from '../../assets/icons/png/ig-icon.png'
import TWIcon from '../../assets/icons/png/tw-icon.png'
import TKIcon from '../../assets/icons/png/tk-icon.png'
import { Map } from "../../components/Map"
import DefaultCover from '../../assets/images/png/app/Default-Cover.png'
import AvatarPhotodefault from '../../assets/images/png/app/AvatarPhotodefault.png'

export const VendorDetailPage = () => {

    const { id } = useParams()
    const { handleGetStore, store } = useGetStore()

    useEffect(() => {
        handleGetStore(id)
    }, [])

    return (

        <main className="mt-0 max-w-[1000px] mr-auto ml-auto">

            <section className='mb-5' style={{display: 'flex', justifyContent: 'center'}}>
            <Link to={`/proveedores/${id}/detalle`} replace className='font-bold text-tailgreen mr-3'> Información General</Link>
            <Link to={`/proveedores/${id}/servicios`} replace className='font-bold text-extradarkgray mr-3 ml-3'> Listado de Servicios</Link>
            <Link to={`/proveedores/${id}/ventas`} replace className='font-bold text-extradarkgray ml-3'>Historial de Ventas</Link>
            </section>

            <section className="w-full h-[271px] relative px-10 py-4 flex mb-4" style={{
                backgroundImage: `linear-gradient(transparent 55%, rgba(0, 0, 0, 0.7)), url("${store?.pictureData?.fullpath ? store?.pictureData?.fullpath :
                        DefaultCover
                    }")`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center'
            }}>
                <div className="flex items-end h-full w-full">
                    <div className="relative w-52 h-52 md:w-40 md:h-40 rounded-full border-8 border-white transform translate-y-1/2 -translate-x-1/2 left-1/2 md:left-0 md:translate-x-0" style={{
                        backgroundImage: `url(${store?.logopath ? store?.logopath :
                                AvatarPhotodefault
                            })`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center'
                    }}>
                    </div>
                    <h3
                        className="hidden md:block text-4xl text-white pl-8 font-bold flex-1" style={{
                            textShadow: '2px 2px 10px rgba(0, 0, 0, 0.8)'
                        }}
                    >
                        {store?.tradeName ?? ''}
                    </h3>
                </div>
            </section>
            <section>
                <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                    <div className="bg-tailgreen p-2 rounded-md flex items-center justify-center mr-2">
                        <a href={store?.facebook}><img src={FBIcon} alt="" className="w-4 h-4 object-contain" /></a>
                    </div>
                    <div className="bg-tailgreen p-2 rounded-md flex items-center justify-center mr-2">
                        <a href={store?.facebook}><img src={IGIcon} alt="" className="w-4 h-4 object-contain" /></a>
                    </div>
                    <div className="bg-tailgreen p-2 rounded-md flex items-center justify-center mr-2">
                        <a href={store?.facebook}><img src={TWIcon} alt="" className="w-4 h-4 object-contain" /></a>
                    </div>
                    <div className="bg-tailgreen p-2 rounded-md flex items-center justify-center mr-2">
                        <a href={store?.facebook}><img src={TKIcon} alt="" className="w-4 h-4 object-contain" /></a>
                    </div>
                </div>
            </section>
            <section className="grid lg:grid-cols-2 pt-8 md:pt-14 pt-28 md:pt-20 mb-8">
                <div className="px-10">
                    <div className="flex flex-col h-full">
                        <h2 className="text-2xl font-semibold">Información General</h2>
                        <table className="w-full table-fixed">
                            <tbody>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Nombre Comercial</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.tradeName}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Teléfono</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.userProviderData?.phoneNumber}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Correo electrónico</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.userProviderData?.email}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Sitio Web</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray"><a href={store?.urlWebSite}>{store?.urlWebSite}</a></td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Contacto</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">
                                        {store?.userProviderData?.name} {store?.userProviderData?.lastNameP} {store?.userProviderData?.lastNameM}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="px-10">
                    <div className="flex flex-col h-full">
                        <h2 className="text-2xl font-semibold">Información Legal</h2>
                        <table className="w-full table-fixed">
                            <tbody>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Representante Legal</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.providerData?.legalRepresentativeName}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Teléfono de Representante</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.providerData?.legalRepresentativePhoneNumber}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Fecha de Constitución</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.providerData?.constitutionDate}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Estructura Legal</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.providerData?.legalStructure}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
            <section className="grid lg:grid-cols-2 mb-8">
                <div className="px-10">
                    <div className="flex flex-col h-full">
                        <h2 className="text-2xl font-semibold">Dirección Comercial</h2>
                        <table className="w-full table-fixed">
                            <tbody>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Calle</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.commercialAddress?.street}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Número Exterior</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.commercialAddress?.exteriorNumber}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Número Interior</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.commercialAddress?.interiorNumber}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Colonia</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.commercialAddress?.neighborhood}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Código Postal</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.commercialAddress?.postalCode}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Municipio/Alcaldía</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.commercialAddress?.municipality}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Estado</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.commercialAddress?.state}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">País</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.commercialAddress?.country}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="px-10">
                    <div className="flex flex-col h-full">
                        <h2 className="text-2xl font-semibold">Dirección Legal</h2>
                        <table className="w-full table-fixed">
                            <tbody>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Calle</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.providerData?.legalAddress?.street}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Número Exterior</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.providerData?.legalAddress?.exteriorNumber}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Número Interior</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.providerData?.legalAddress?.interiorNumber}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Colonia</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.providerData?.legalAddress?.neighborhood}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Código Postal</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.providerData?.legalAddress?.postalCode}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Municipio/Alcaldía</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.providerData?.legalAddress?.municipality}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Estado</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.providerData?.legalAddress?.state}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">País</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.providerData?.legalAddress?.country}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
            <section className="grid lg:grid-cols-2">
                <div className="px-10">
                    <section className="flex flex-col">
                        <div className="w-full h-96">
                            <Map center={{
                                lat: store?.commercialAddress?.latitude ?? 19.073344219245,
                                lng: store?.commercialAddress?.longitude ?? -98.25546356592
                            }} />
                        </div>
                    </section>
                </div>
                <div className="px-10">
                    <div className="flex flex-col">
                        <h2 className="text-2xl font-semibold">Información Bancaria</h2>
                        <table className="w-full table-fixed">
                            <tbody>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Banco o Institución</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.providerData?.bankInfo?.institutionName}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Dirección</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.providerData?.bankInfo?.address}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Beneficiario</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.providerData?.bankInfo?.beneficiaryName}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">No. de Sucursal</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.providerData?.bankInfo?.storeNumber}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">No. de Cuenta</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.providerData?.bankInfo?.accountNumber}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Cuenta CLABE</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{store?.providerData?.bankInfo?.accountCLABE}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
            <section className="border-t-[1px] border-[#E4E3E5] flex">
                { <NavLink to="/proveedores" className="my-9 ml-auto">
                    <button
                        className="btn-prymary w-[250px] btn-small"
                        type="button"
                    >
                        Regresar
                    </button>
                </NavLink>}
            </section>
        </main>
    )
}