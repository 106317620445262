import { Dialog, Switch, Transition } from '@headlessui/react'
import { ExclamationIcon, EyeIcon } from '@heroicons/react/outline'
import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/solid'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import OrderIcon from '../../components/OrderIcon'
import Pagination from '../../components/Pagination'
import SearchInput from '../../components/SearchInput'
import { Table, Td, Th } from '../../components/Table'
import useDeleteStore from '../../hooks/stores/useDeleteStore'
import useGetStores from '../../hooks/stores/useGetStores'
import { useDebounce } from '../../hooks/utils/useDebounce'
import { classNames } from '../../utils/CssHelpers'
import AvatarPhotodefault from '../../assets/images/png/app/AvatarPhotodefault.png'
import useUpdateStoreStatus from '../../hooks/stores/useUpdateStoreStatus'

export const VendorsPage = () => {

    const getStores = useGetStores()
    const deleteStore = useDeleteStore()
    const updateStoreStatus = useUpdateStoreStatus()

    const [search, setSearch] = useState('')
    const fieldSearch = { value: search, onChange: (e) => setSearch(e.target.value) }
    const debouncedSearch = useDebounce(search, 500);

    const [store, setStore] = useState(null)
    const [openModalDelete, setOpenModalDelte] = useState(false)
    const cancelButtonModalDeleteRef = useRef(null)

    const showModalDelete = (store) => {
        setStore(store)
        setOpenModalDelte(true)
    }

    const deleteVendor = async (objectId) => {
        const r = await deleteStore.handleDeleteStore(objectId)
        if (r)
            getStores.setParams({ ...getStores.params })
        setOpenModalDelte(false)
    }

    const onChangueStatus = (e) => {
        const isActiveFilterAux = e.target.value
        const { isActiveFilter, ...resp } = getStores.params
        getStores.setParams({
            ...resp,
            pageNum: 0,
            ...(isActiveFilterAux !== '' ? { isActiveFilter: isActiveFilterAux } : {}),
        })
    }

    const updateStatus = async (store) => {
        console.log(store);
        const resp = await updateStoreStatus.handleUpdateStoreStatus({
            storeObjectId: store.objectId,
            isActive: store.isActive ? 0 : 1
        })
        if (resp)
            getStores.setParams({ ...getStores.params, })  /*  */
    }

    const onChangueField = (field) => {
        getStores.setParams({
            ...getStores.params,
            sortField: field,
            sortDir: getStores.params.sortDir * -1
        })
    }

    const onChanguePage = (p) => {
        getStores.setParams({
            ...getStores.params,
            pageNum: (p - 1)
        })
    }

    useEffect(() => {
        getStores.handleGetStores()
        return () => { };
    }, [getStores.params])

    useEffect(() => {
        getStores.setParams({
            ...getStores.params,
            filter: debouncedSearch,
            pageNum: 0
        })
        return () => { };
    }, [debouncedSearch])

    return (

        <div className="flex flex-col">

            <div className="-my-2 sm:-mx-6 lg:-mx-8">

                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">

                    <div className='flex mb-5 items-center'>

                        <div className='w-2/3 lg:w-1/2'>

                            <SearchInput
                                field={fieldSearch}
                                meta={{ touched: search }}
                                label='Buscar'
                            ></SearchInput>

                        </div>

                        <div className='ml-auto mr-1'>

                            <NavLink
                                to={`/proveedores/crear`}
                                className='w-full flex flex-row items-center justify-center group lg:justify-end'>
                                <div className='rounded-full bg-tailgreen p-2 text-white group-hover:bg-mediumgreen'>
                                    <PlusIcon className='w-5 h-5'></PlusIcon>
                                </div>

                                <p
                                    className='text-tailgreen pl-2 text-xl group-hover:text-mediumgreen hidden lg:block'
                                >Agregar proveedor</p>
                            </NavLink>

                        </div>

                    </div>

                    <div className='grid grid-cols-1 pb-5'>
                        {
                            getStores.loading || deleteStore.loading ?
                                <div className='flex justify-center mt-20'>
                                    <p className='h2'> Cargando...</p>
                                </div>
                                :
                                <>
                                    {
                                        (getStores.stores && getStores.stores.length !== 0) ?
                                            <Table>
                                                <thead className="">
                                                    <tr>
                                                        <Th className="px-6 min-w-[100px]">
                                                            <OrderIcon
                                                                field={"stores.identifier"}
                                                                params={getStores.params}
                                                                onChange={onChangueField}
                                                            >ID</OrderIcon>
                                                        </Th>
                                                        <Th className="px-6 min-w-[100px]">
                                                            <OrderIcon
                                                                field={"stores.tradeName"}
                                                                params={getStores.params}
                                                                onChange={onChangueField}
                                                            >Nombre</OrderIcon>
                                                        </Th>
                                                        <Th className="px-6 min-w-[100px]">
                                                            Dirección
                                                        </Th>
                                                        <Th className="px-6 min-w-[100px]" >
                                                            <OrderIcon
                                                                field={"users.phoneNumber"}
                                                                params={getStores.params}
                                                                onChange={onChangueField}
                                                            >Teléfono</OrderIcon>
                                                        </Th>
                                                        <Th className="px-6 min-w-[100px]" >
                                                            <OrderIcon
                                                                field={"users.email"}
                                                                params={getStores.params}
                                                                onChange={onChangueField}
                                                            >Email</OrderIcon>
                                                        </Th>

                                                        <Th className="px-6 min-w-[100px]" >
                                                            <OrderIcon
                                                                field={"stores.contactName"}
                                                                params={getStores.params}
                                                                onChange={onChangueField}
                                                            >Contacto</OrderIcon>
                                                        </Th>

                                                        <Th className="px-6 min-w-[100px]" >
                                                            <select
                                                                className='multiselect__input bg-tailgreen text-white uppercase font-semibold text-sm pl-0'
                                                                onChange={onChangueStatus}
                                                                value={getStores.params.isActiveFilter}
                                                            >
                                                                <option value="" className='option'>Estatus</option>
                                                                <option value="1" className='option'>Activo</option>
                                                                <option value="0" className='option'>Inactivo</option>
                                                            </select>
                                                        </Th>
                                                        <Th scope="col" className="px-6 py-3">
                                                        </Th>
                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {getStores.stores.map((store, i) => (
                                                        <tr key={store.objectId} className=''>

                                                            <Td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="table-text">{store.identifier}</div>
                                                            </Td>

                                                            <Td className="px-6 py-4 whitespace-nowrap">

                                                                <div className="flex items-center">
                                                                    <div className="flex-shrink-0 h-20 w-20">
                                                                        <img
                                                                            className="h-20 w-20 rounded-full"
                                                                            src={store.logopath || AvatarPhotodefault}
                                                                            onError={(e) => e.target.src = AvatarPhotodefault}
                                                                            alt="" />
                                                                    </div>
                                                                    <div className="ml-4">
                                                                        <div className="table-text">{store.tradeName}</div>
                                                                    </div>
                                                                </div>
                                                            </Td>
                                                            <Td className="px-6 py-4 w-96">
                                                                <div className="table-text">
                                                                    {store?.commercialAddress?.street}{" "}
                                                                    {store?.commercialAddress?.interiorNumber}</div>
                                                                <div className="table-text">
                                                                    {store?.commercialAddress?.municipality}{" "}
                                                                    {store?.commercialAddress?.city}
                                                                </div>
                                                            </Td>
                                                            <Td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="table-text">{store?.userProviderData?.phoneNumber}</div>
                                                            </Td>
                                                            <Td className="px-6 py-4 whitespace-nowrap">

                                                                <div className="table-text">{store?.userProviderData?.email}</div>
                                                            </Td>
                                                            <Td className="px-6 py-4 whitespace-nowrap">

                                                                <div className="table-text">{store?.contactName}</div>
                                                            </Td>
                                                            <Td className="px-6 py-4 whitespace-nowrap">
                                                                <Switch.Group>
                                                                    <div className="flex items-center">
                                                                        <Switch
                                                                            checked={store.isActive}
                                                                            onChange={(v) => updateStatus(store)}
                                                                            className={`${store.isActive ? 'bg-green-600' : 'bg-darkred'
                                                                                } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
                                                                        >
                                                                            <span
                                                                                className={`${store.isActive ? 'translate-x-6' : 'translate-x-1'
                                                                                    } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                                                                            />
                                                                        </Switch>
                                                                        <p className={
                                                                            classNames(
                                                                                "ml-2",
                                                                                store.isActive ? 'text-green-600' : 'text-darkred')
                                                                        }>
                                                                            {store.isActive ? 'Activo' : 'Inactivo'}
                                                                        </p>
                                                                    </div>
                                                                </Switch.Group>
                                                            </Td>
                                                            <Td>
                                                                <div className="flex flex-row justify-center items-center">
                                                                    <NavLink
                                                                        to={`/proveedores/`+store.objectId+`/detalle`}
                                                                        className='w-full flex flex-row items-center justify-center group lg:justify-end'>
                                                                        <div className="text-darkgreen hover:text-tailgreen mt-1">
                                                                            <EyeIcon className='w-6 h-6 mr-3' />
                                                                        </div>
                                                                    </NavLink>
                                                                    <NavLink
                                                                        to={`/proveedores/`+store.objectId+`/actualizar`}
                                                                        className='w-full flex flex-row items-center justify-center group lg:justify-end'>
                                                                        <div className="text-darkgreen hover:text-tailgreen mt-1">
                                                                            <PencilIcon className='w-6 h-6 mr-3' />
                                                                        </div>
                                                                    </NavLink>
                                                                    <button
                                                                        onClick={() => showModalDelete(store)}
                                                                        className="text-tailgreen hover:text-mediumgreen mt-1"
                                                                    >
                                                                        <TrashIcon className='w-6 h-6 mr-5' />
                                                                    </button>
                                                                </div>
                                                            </Td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            :
                                            <div className='flex justify-center mt-20'>
                                                <p className='h2'>  No hay resultados</p>
                                            </div>
                                    }

                                    {
                                        (getStores.pagination && getStores.pagination.last_page !== 1) ?
                                            <Pagination paginate={getStores.pagination} onChangue={onChanguePage}></Pagination>
                                            : null
                                    }
                                </>
                        }
                    </div>

                </div>
            </div>

            <Transition.Root show={openModalDelete} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonModalDeleteRef} onClose={setOpenModalDelte}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                                Elimindar proveedor
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Deseas eliminar el proveedor {store?.tradeName}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-300"
                                        onClick={() => deleteVendor(store?.objectId)}
                                        disabled={deleteStore.loading}
                                    >
                                        Eliminar
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-300"
                                        onClick={() => setOpenModalDelte(false)}
                                        disabled={deleteStore.loading}
                                        ref={cancelButtonModalDeleteRef}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )
}