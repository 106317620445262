import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { errorAlert, unauthorizedUser } from '../../utils/AlertToast'
import { api } from '../../utils/ApiMethods'

const useGetProfile = () => {
    const { authAction, auth } = useAuth()
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState()

    const handleGetProfile = async () => {
        setLoading(true)
        try {
            const response = await api(auth.token).getPost('getAdminProfile').sendJson()
            const data = await response.json()

            setLoading(false)
            if (response.status === 200) {
                setUser(data)
                return true
            }
            unauthorizedUser(response.status, authAction, data.message)
        } catch (e) {
            setLoading(false)
            errorAlert()
        }
        return false
    }

    return { handleGetProfile, loading, user }

}

export default useGetProfile