import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { errorAlert, unauthorizedUser } from '../../utils/AlertToast'
import { api } from '../../utils/ApiMethods'

const useGetAllPetTypes = () => {

    const { authAction, auth } = useAuth()

    const [loading, setLoading] = useState(false)
    const [types, setTypes] = useState([])

    const handleGetAllPetTypes = async () => {

        setLoading(true)

        try {

            const response = await api(auth.token).getPost('getAllPetTypes').sendJson({ storeObjectId: auth?.user?.storeData?.objectId })
            const data = await response.json()

            setLoading(false)

            if (response.status === 200) {

                setTypes(data)
                return true
            }
            unauthorizedUser(response.status, authAction, data.message)
        } catch (e) {
            setLoading(false)
            errorAlert()
        }

        return false
    }

    return { handleGetAllPetTypes, loading, types, setTypes }

}

export default useGetAllPetTypes