import { classNames } from '../utils/CssHelpers'

export const MiniFieldInput = ({ field, meta, type = 'text', disabled = false, isUrl = false }) => {
    return (
        <input
            type={type}
            disabled={disabled}
            className={classNames(
                (meta?.touched && meta?.error) ? 'focus:ring-darkred ring-darkred' : 'focus:ring-tailgreen ring-mediumgray',
                isUrl && 'disabled:underline',
                'disabled:ring-0 ring-1 focus:ring-2',
                'focus:outline-0 outline-0',
                'focus:border-0 border-0',
                'font-semibold py-1 px-2 text-darkgray w-full rounded-lg block disabled:bg-white bg-semigray focus:bg-semiwhite focus:text-black',
            )}
            {...field}
        />
    )
}