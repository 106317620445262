import React from 'react'
import Logo from '../../assets/images/svg/app/Logo.svg'

export const LoginMovilPage = () => {

    return (
        <div className="min-h-screen flex items-center justify-center px-4 sm:px-6 lg:px-8 py-12 login">
            <div className="w-full max-w-lg z-10">
                <div>
                    <img
                        className="mx-auto w-auto h-28"
                        src={Logo}
                        alt="Workflow"
                    />
                </div>
                <div className='text-tailgreen mt-10 text-2xl font-semibold text-center'>
                    La contraseña se ha reestablecido, inicia sesión en tu aplicación movil.
                </div>
                
            </div>
        </div>
    )
}