import React from 'react'

export const UserCreatePage = () => {

    return (

        <div className="flex flex-col">
            <h1>Crear usuario</h1>
        </div>
    )
}