import { useNavigate } from "react-router"
import MyHeader from "../components/MyHeader"
import { useAuth } from "../context/AuthContext"
import { types } from "../models/typeAuth"

export const RegisterRouteLayout = ({ children }) => {
    const { auth, authAction } = useAuth()

    const navigate = useNavigate()

    const handleLogout = () => {

        authAction({ type: types.logout })

        navigate('/login', {
            replace: true
        });
    }
    return (
        <div className="">
            <MyHeader handleLogout={handleLogout} auth={auth}></MyHeader>

            <main className="max-w-[1550px] mx-auto px-2 sm:px-6 lg:px-8 pt-10 w-full">
                {children}
            </main>
        </div >
    )
}