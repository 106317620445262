import 'core-js/es/promise'
import 'core-js/es/set'
import 'core-js/es/map'
import * as Yup from 'yup'

const UpdateProfileSchema = Yup.object().shape({
    name: Yup.string().required('Elemento requerido.'),
    lastNameP: Yup.string().required('Elemento requerido.'),
    lastNameM: Yup.string().required('Elemento requerido.'),
    phoneNumber: Yup.string().required('Elemento requerido.'),
    email: Yup.string().email('Correo inválido').required('Elemento requerido.'),
    password: Yup.string().notRequired(),
    confirmPassword: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
            [Yup.ref('password')],
            'Las contraseñas no coinciden'
        ).required('Elemento requerido')
    })
})

export { UpdateProfileSchema }