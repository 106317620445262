import { Route, Routes } from 'react-router-dom'
import { ForgotPasswordPage } from '../pages/forgotPassword/ForgotPasswordPage'
import { ResetPasswordPage } from '../pages/forgotPassword/ResetPasswordPage'
import { LoginMovilPage } from '../pages/forgotPassword/LoginMovilPage'
import { ConfirmAccountPage } from '../pages/forgotPassword/ConfirmAccount'
import { LoginPage } from '../pages/login/LoginPage'
import { PrivateRoute } from './PrivateRoute'
import { PublicRoute } from './PublicRoute'
import { RegisterRoute } from './RegisterRoute'


export const AppRouter = () => {

    return <Routes>
        <Route path="/login" element={
            <PublicRoute>
                <LoginPage></LoginPage>
            </PublicRoute>
        } />
        <Route path="/forgot-password" element={
            <PublicRoute>
                <ForgotPasswordPage></ForgotPasswordPage>
            </PublicRoute>
        } />
        <Route path="/reset-password/:typeUser/:code" element={
            <PublicRoute>
                <ResetPasswordPage></ResetPasswordPage>
            </PublicRoute>
        } />
        <Route path="/app" element={
            <PublicRoute>
                <LoginMovilPage></LoginMovilPage>
            </PublicRoute>
        } />
        <Route path="/confirm-account/:code" element={
            <PublicRoute>
                <ConfirmAccountPage></ConfirmAccountPage>
            </PublicRoute>
        } />
        <Route path="/*" element={
            <PrivateRoute>
                <RegisterRoute />
            </PrivateRoute>
        } />
    </Routes>
}