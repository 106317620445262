import { Fragment } from 'react'
import { Disclosure, Menu, Popover, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { NavLink } from 'react-router-dom'
import { classNames } from '../utils/CssHelpers'
import logo from '../assets/images/svg/app/Logo.svg'
import { useState } from 'react'
import AvatarPhotodefault from '../assets/images/png/app/AvatarPhotodefault.png'
import { MySidebar } from './MySidebar'
import useGetAllNotifications from '../hooks/notifications/useGetAllNotifications'
import useGetProfile from '../hooks/profile/useGetProfile'
import { MyNotificationPanel } from './MyNotificationPanel'
import { useEffect } from 'react'

const navigation = [
    { name: 'Usuarios', to: '/usuarios' },
    { name: 'Proveedores', to: '/proveedores' },
    { name: 'Histórico', to: '/historico' },
    { name: 'Reseñas', to: '/reviews' },
]

const areNotificationsUnseen = (notifications) => {
    return notifications?.some(notification => !notification?.seen)
}

export default function MyHeader({ handleLogout, auth }) {

    const [isOpen, setIsOpen] = useState(false)
    const [isOpenNotif, setIsOpenNotif] = useState(false)
    const { user, handleGetProfile } = useGetProfile()
    const allNotifications = useGetAllNotifications()

    useEffect(() => {
        handleGetProfile()
    }, [])

    return (
        <div>
        <Disclosure as="nav" className="bg-semigray">
            {({ open }) => (
                <>
                    <div className="sm:px-6 lg:px-8 max-w-[1600px] mx-auto px-2">
                        <div className="relative flex items-center justify-between h-[100px] sm:h-[150px] lg:h-[120px]">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-darkgray hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-darkgray">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XIcon className="block h-8 w-8" aria-hidden="true" />
                                    ) : (
                                        <MenuIcon className="block h-8 w-8" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex-shrink-0 flex items-center pb-2">

                                    <img
                                        className="block h-10 sm:10 md:h-12 lg:h-[60px] w-auto"
                                        src={logo}
                                        alt="Workflow"
                                    />
                                </div>
                                <div className="hidden sm:flex sm:ml-16 lg:ml-24 items-center">
                                    <div className="flex space-x-0 md:space-x-3 lg:space-x-12">
                                        {navigation.map((item) => (
                                            <NavLink
                                                key={item.name}
                                                to={item.to}
                                                className={({ isActive }) => classNames(
                                                    isActive ? 'text-tailgreen' : 'text-extradarkgray hover:text-tailgreen ',
                                                    'rounded-md linkHeader'
                                                )}
                                            >
                                                {item.name}
                                            </NavLink>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                {/* <button
                                    type="button"
                                    className="p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                >
                                    <span className="sr-only">View notifications</span>
                                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                                </button> */}

                                {/* Notifications dropdown */}
                                <div className="relative z-10">
                                        {/* <Popover.Button
                                                        type="button"
                                                        className={classNames(
                                                            "p-1 rounded-full hover:text-white sm:mx-5",
                                                            open ? 'text-white outline-none ring-2 ring-offset-2 ring-offset-gray-800 ring-white' : 'text-gray-400'
                                                        )}
                                                    >
                                                        <span className="sr-only">View notifications</span> */}
                                        {areNotificationsUnseen(allNotifications?.notifications) && (<div className="absolute w-[0.625rem] h-[0.625rem] rounded-full bg-tailgreen right-0" />)}
                                        <BellIcon className="h-8 sm:h-10 lg:h-[36px] cursor-pointer" aria-hidden="true" onClick={() => {
                                            setIsOpenNotif(!isOpenNotif)
                                            setIsOpen(false)
                                        }}  />
                                        {/* </Popover.Button> */}
                                    </div>
                                {/* <Popover as="div" className="relative z-10">
                                    {({ open }) => (
                                        <>
                                            <div>
                                                <Popover.Button
                                                    type="button"
                                                    className={classNames(
                                                        "p-1 rounded-full hover:text-white sm:mx-5",
                                                        open ? 'text-white outline-none ring-2 ring-offset-2 ring-offset-gray-800 ring-white' : 'text-gray-400'
                                                    )}
                                                >
                                                    <span className="sr-only">View notifications</span>
                                                    <BellIcon className="h-8 sm:h-10 lg:h-[36px]" aria-hidden="true" />
                                                </Popover.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Popover.Panel className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    {({ close }) => (
                                                        <div className='z-50'>
                                                            <div
                                                                onClick={() => { console.log(1); close() }}
                                                                className={classNames(
                                                                    'px-4 py-2 text-sm bg-gray-50 w-full text-left flex flex-row',
                                                                    'hover:bg-gray-100'
                                                                )}
                                                            >
                                                                <div>

                                                                    <p> Notificacion 1</p>
                                                                    <p className="text-[8px]"> 15/01/2022</p>

                                                                </div>
                                                                <div className="ml-auto ">
                                                                    <button
                                                                        className="hover:bg-gray-400 hover:rounded-full w-5 h-5 p-1"
                                                                        onClick={(e) => { console.log(2); close(); e.stopPropagation(); }}
                                                                    >
                                                                        <XIcon className='w-3 h-3'></XIcon>
                                                                    </button>

                                                                </div>

                                                            </div>
                                                            <div></div>
                                                            <div
                                                                onClick={() => { console.log(1); close() }}
                                                                className={classNames(
                                                                    'px-4 py-2 text-sm bg-gray-50 w-full text-left flex flex-row',
                                                                    'hover:bg-gray-100'
                                                                )}
                                                            >
                                                                <div>

                                                                    <p> Notificacion 1</p>
                                                                    <p className="text-[8px]"> 15/01/2022</p>

                                                                </div>
                                                                <div className="ml-auto ">
                                                                    <button
                                                                        className="hover:bg-gray-400 hover:rounded-full w-5 h-5 p-1"
                                                                        onClick={(e) => { console.log(2); close(); e.stopPropagation(); }}
                                                                    >
                                                                        <XIcon className='w-3 h-3'></XIcon>
                                                                    </button>

                                                                </div>

                                                            </div>
                                                            <div
                                                                onClick={() => { console.log(1); close() }}
                                                                className={classNames(
                                                                    'px-4 py-2 text-sm bg-gray-50 w-full text-left flex flex-row',
                                                                    'hover:bg-gray-100'
                                                                )}
                                                            >
                                                                <div>

                                                                    <p> Notificacion 1</p>
                                                                    <p className="text-[8px]"> 15/01/2022</p>

                                                                </div>
                                                                <div className="ml-auto ">
                                                                    <button
                                                                        className="hover:bg-gray-400 hover:rounded-full w-5 h-5 p-1"
                                                                        onClick={(e) => { console.log(2); close(); e.stopPropagation(); }}
                                                                    >
                                                                        <XIcon className='w-3 h-3'></XIcon>
                                                                    </button>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    )}
                                                </Popover.Panel>
                                            </Transition>
                                        </>
                                    )}
                                </Popover> */}
                                {/* Profile dropdown */}
                                <Menu as="div" className="ml-3 relative">
                                        <div>
                                            {/* <Menu.Button className="flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                                <span className="sr-only">Open user menu</span>
                                            </Menu.Button> */}
                                            <button
                                                onClick={() => {
                                                    setIsOpen(!isOpen)
                                                    setIsOpenNotif(false)
                                                }}
                                                className="flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                            >
                                                <img
                                                    className="w-12 sm:w-[60px] lg:w-[63px] h-12 sm:h-[60px] lg:h-[63px] rounded-full object-cover"
                                                    src={user?.pictureData?.fullpath ?? AvatarPhotodefault}
                                                    alt=""
                                                />
                                            </button>
                                        </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        {/* <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <Menu.Item>
                                                <NavLink
                                                    to={'perfil'}
                                                    className={({ isActive }) => classNames(
                                                        isActive ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700'
                                                    )}
                                                >
                                                    Perfil
                                                </NavLink>
                                            </Menu.Item>
                                            {/* <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                    >
                                                        Configuraciones
                                                    </a>
                                                )}
                                            </Menu.Item> */}
                                            {/* <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        onClick={handleLogout}
                                                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 w-full text-left')}
                                                    >
                                                        Salir
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items> */} 
                                    </Transition>
                                </Menu>
                                <h2 className='linkHeader ml-5 hidden xl:flex '>{auth?.user?.name ?? 'Tails Society'}</h2>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            {navigation.map((item) => (
                                <Disclosure.Button
                                    key={item.name}
                                    className="block w-full"
                                >
                                    <NavLink
                                        key={item.name}
                                        to={item.to}
                                        className={({ isActive }) => classNames(
                                            isActive ? 'text-tailgreen' : 'text-darkgray hover:bg-gray-100 hover:text-tailgreen',
                                            'block px-3 py-2 rounded-md text-base font-medium w-full'
                                        )}
                                    >
                                        {item.name}
                                    </NavLink>
                                </Disclosure.Button>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
        <MySidebar
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                handleLogout={handleLogout}
                handleGetProfile={handleGetProfile}
                user={user}
            />
        <MyNotificationPanel
                isOpen={isOpenNotif}
                setIsOpen={setIsOpenNotif}
                allNotifications={allNotifications}
            />
        </div>
    )
}
