import { classNames } from "../utils/CssHelpers"
import Pencil from '../assets/icons/svg/pensil.svg'
import { useEffect, useState } from "react"
import { Field, Form, Formik } from "formik"
import { UpdateProfileSchema } from "../schemas/ProfileSchemas"
import { MiniFieldInput } from "./MiniFieldInput"
import useUpdateProfile from "../hooks/profile/useUpdateProfile"
import AvatarDefault from '../assets/images/png/app/AvatarPhotodefault.png'


export const MySidebar = ({ isOpen, setIsOpen, handleLogout, user, handleGetProfile}) => {
    const [isEditable, setIsEditable] = useState(false)
    const updateProfile = useUpdateProfile()

    const handleSubmit = async (values) => {
        if (!isEditable) return false
            await updateProfile.handleUpdateProfile(values)
        setIsEditable(false)
        await handleGetProfile()
    }

    return (
        <section
            className={classNames(
                "overflow-hidden min-h-[calc(100vh-100px)] sm:min-h-[calc(100vh-150px)] lg:min-h-[calc(100vh-120px)] transition-all duration-300 transform absolute left-0 top-[100px] sm:top-[150px] lg:top-[120px] w-full z-10 bg-black bg-opacity-20 flex",
                isOpen ? 'bg-opacity-20 cursor-pointer' : 'bg-opacity-0 pointer-events-none'
            )}
            onClick={(e) => {
                setIsOpen(false)
            }}
        >
            <Formik
                enableReinitialize={true}
                initialValues={{
                    name: user?.name ?? '',
                    lastNameP: user?.lastNameP ?? '',
                    lastNameM: user?.lastNameM ?? '',
                    phoneNumber: user?.phoneNumber ?? '',
                    email: user?.email ?? ''
                }}
                validationSchema={UpdateProfileSchema}
                onSubmit={handleSubmit}
            >
                {({ errors, setFieldValue, values, submitForm, touched }) => (
                    <Form>
                        <aside
                            onClick={e => e.stopPropagation()}
                            className={classNames(
                                "h-full w-full md:w-3/4 xl:w-1/2 bg-white ml-auto transform transition-all duration-300 cursor-auto",
                                isOpen ? 'translate-x-0 opacity-100' : 'translate-x-1/3 opacity-0 pointer-events-none'
                            )}
                        >
                            <h2 className="font-semibold text-3xl py-8 px-8">Tu perfil</h2>
                            <section className="w-full h-[271px] relative px-10 py-4 flex" style={{
                                backgroundImage: `linear-gradient(transparent 55%, rgba(0, 0, 0, 0.7)), url("${values?.logo ? URL.createObjectURL(values.picture) :
                                    user?.logopath ? user?.logopath :
                                        'https://naturaldogtreats.org/wp-content/uploads/2021/10/4.jpg'
                                    }")`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center center'
                            }}>
                                <div className="flex items-end h-full w-full">
                                    <div className="relative w-52 h-52 md:w-40 md:h-40 rounded-full border-8 border-white transform translate-y-1/2 -translate-x-1/2 left-1/2 md:left-0 md:translate-x-0" style={{
                                        backgroundImage: `url(${values?.picture ? URL.createObjectURL(values.picture) :
                                            user?.pictureData?.fullpath ? user?.pictureData?.fullpath :
                                            AvatarDefault
                                            })`,
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center center'
                                    }}>
                                        <div className="absolute rounded-full bg-white bottom-0 right-0 p-2 shadow hover:bg-mediumgray">
                                            <label htmlFor="logo">
                                                <img src={Pencil} alt="" className="h-8 w-8 cursor-pointer" />
                                            </label>
                                        </div>
                                        <input
                                            type="file"
                                            name="logo"
                                            id="logo"
                                            className="hidden"
                                            onChange={e => {
                                                setFieldValue('picture', e?.target?.files[0])
                                                setIsEditable(true)
                                            }}
                                        />
                                    </div>
                                    <h3
                                        className="hidden md:block text-4xl text-white pl-8 font-bold flex-1" style={{
                                            textShadow: '2px 2px 10px rgba(0, 0, 0, 0.8)'
                                        }}
                                    >
                                        {'Tails Society'}
                                    </h3>
                                </div>
                            </section>
                            <section className="p-8 md:p-14 pt-28 md:pt-20 pb-4">
                                <div className="grid md:grid-cols-1 gap-8">
                                    <section>
                                        <h4 className="text-xl font-semibold mb-4 pl-1">Información</h4>
                                        <table className="table-fixed w-full">
                                            <tbody>
                                                <tr>
                                                    <td className="font-semibold p-1 outline-tailgreen">Nombre </td>
                                                    <td className="pl-4">
                                                        <Field name="name">
                                                            {(attr) => <MiniFieldInput disabled={!isEditable} {...attr} />}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                {errors?.name && (
                                                    <tr className="w-full">
                                                        <td></td>
                                                        <td className="w-full pl-4">
                                                            <div className="text-darkred w-full font-semibold text-sm mt-1">{errors?.name}</div>
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <td className="font-semibold p-1 outline-tailgreen">Apellido Paterno</td>
                                                    <td className="pl-4">
                                                        <Field name="lastNameP">
                                                            {(attr) => <MiniFieldInput disabled={!isEditable} {...attr} />}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                {errors?.lastNameP && (
                                                    <tr className="w-full">
                                                        <td></td>
                                                        <td className="w-full pl-4">
                                                            <div className="text-darkred w-full font-semibold text-sm mt-1">{errors?.lastNameP}</div>
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <td className="font-semibold p-1 outline-tailgreen">Apellido Materno</td>
                                                    <td className="pl-4">
                                                        <Field name="lastNameM">
                                                            {(attr) => <MiniFieldInput disabled={!isEditable} {...attr} />}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                {errors?.lastNameM && (
                                                    <tr className="w-full">
                                                        <td></td>
                                                        <td className="w-full pl-4">
                                                            <div className="text-darkred w-full font-semibold text-sm mt-1">{errors?.lastNameM}</div>
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr className="">
                                                    <td className="pt-1 font-semibold p-1 outline-tailgreen">Teléfono</td>
                                                    <td className="pl-4 pt-1">
                                                        <Field name="phoneNumber">
                                                            {(attr) => <MiniFieldInput disabled={!isEditable} {...attr} />}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                {errors?.phoneNumber && (
                                                    <tr className="w-full">
                                                        <td colSpan={2} className="w-full">
                                                            <div className="text-right text-darkred w-full font-semibold text-sm mt-1">{errors?.phoneNumber}</div>
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr className="">
                                                    <td className="pt-1 font-semibold p-1 outline-tailgreen">Correo electrónico</td>
                                                    <td className="pl-4 pt-1">
                                                        <Field name="email">
                                                            {(attr) => <MiniFieldInput disabled={!isEditable} {...attr} />}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                {errors?.email && (
                                                    <tr className="w-full">
                                                        <td colSpan={2} className="w-full">
                                                            <div className="text-right text-darkred w-full font-semibold text-sm mt-1">{errors.email}</div>
                                                        </td>
                                                    </tr>
                                                )}
                                                {isEditable && (<>
                                                    <tr className="">
                                                        <td className="pt-1 font-semibold p-1 outline-tailgreen">Contraseña</td>
                                                        <td className="pl-4 pt-1">
                                                            <Field name="password">
                                                                {(attr) => <MiniFieldInput type="password" disabled={!isEditable} {...attr} />}
                                                            </Field>
                                                        </td>
                                                    </tr>
                                                    {errors?.password && (
                                                        <tr className="w-full">
                                                            <td colSpan={2} className="w-full">
                                                                <div className="text-right text-darkred w-full font-semibold text-sm mt-1">{errors?.password}</div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    <tr className="">
                                                        <td className="pt-1 font-semibold p-1 outline-tailgreen">Confirmar</td>
                                                        <td className="pl-4 pt-1">
                                                            <Field name="confirmPassword">
                                                                {(attr) => <MiniFieldInput type="password" disabled={!isEditable} {...attr} />}
                                                            </Field>
                                                        </td>
                                                    </tr>
                                                    {(errors?.confirmPassword) && (
                                                        <tr className="w-full">
                                                            <td colSpan={2} className="w-full">
                                                                <div className="text-right text-darkred w-full font-semibold text-sm mt-1">{errors?.confirmPassword}</div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>)}
                                            </tbody>
                                        </table>
                                    </section>
                                    
                                </div>
                                <div className="border-t-[1px] mt-8 border-mediumgray">
                                    <div className="flex flex-col md:flex-row justify-between items-center mt-4">
                                        <button
                                            className="text-xl mt-4 md:mt-0 order-last md:order-first font-semibold pl-1 text-darkgray hover:text-tailgreen"
                                            type="button"
                                            onClick={handleLogout}
                                        >
                                            Cerrar sesión
                                        </button>
                                        {isEditable ? (
                                            <button
                                                className="btn-prymary w-[250px] btn-small"
                                                type="button"
                                                onClick={submitForm}
                                            >
                                                Guardar
                                            </button>
                                        ) : (
                                            <button
                                                className="btn-prymary w-[250px] btn-small"
                                                type="button"
                                                onClick={() => setIsEditable(true)}
                                            >
                                                Editar información
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </section>
                        </aside>
                    </Form>
                )}
            </Formik>
        </section>
    )
}