import React from 'react'
import { useParams } from 'react-router-dom'

export const UserEditPage = () => {

    const { id } = useParams()

    return (

        <div className="flex flex-col">
            <h1>Editar usuario {id}</h1>
        </div>
    )
}