import React from 'react'
import { useEffect } from "react"
import { NavLink, useParams } from 'react-router-dom'
import useGetAppointmentById from "../../hooks/appointments/useGetAppointmentById"
import AvatarPhotodefault from '../../assets/images/png/app/AvatarPhotodefault.png'
import PetPhotodefault from '../../assets/images/png/app/PetPhotoDefault.png'
import { Field, Form, Formik } from "formik"
import { classNames } from "../../utils/CssHelpers"

const inputBG = (touched, error) => (touched) ? (error ?
    "ring-darkred border-darkred focus:border-darkred focus:ring-darkred "
    :
    "ring-tailgreen border-tailgreen focus:border-tailgreen focus:ring-tailgreen"
) : "ring-gray-300 border-gray-300 focus:border-gray-300 focus:ring-gray-300"

export const AppointmentDetailPage = () => {

    const { id } = useParams()
    const { handleGetAppointmentById, appointment } = useGetAppointmentById()
    //const history = useHistory();

    const formatCurrency = amount => {
        const options = { style: 'currency', currency: 'USD' };
        const numberFormat = new Intl.NumberFormat('en-US', options);

        return numberFormat.format(amount);
    }

    const getProfit = (servicePrice, serviceFeePercentage) => {
        return servicePrice * serviceFeePercentage;
    }

    const renderAppointmentStatus = (param) => {
        switch(param) {
            case 0:
                return 'Enviada';
            case 1:
                return 'Aceptada';
            case 2:
                return 'Rechazada';
            case 3:
                return 'Cancelada';
            case 4:
                return 'En Proceso';
            case 5:
                return 'Terminada';
            default:
                return '';
        }
    }

    useEffect(() => {
        handleGetAppointmentById({
            appointmentObjectId: id
        })
    }, [])

    return (

        <main className="mt-10">
            <section className="grid lg:grid-cols-3">
                <div className="border-dashed border-r-[1px] border-[#E4E3E5] px-10">
                    <div className="flex flex-col h-full">

                        <h2 className="text-2xl font-semibold">Sobre el cliente</h2>
                        <img src={appointment?.userClientData?.pictureData?.fullpath ? appointment?.userClientData?.pictureData?.fullpath : AvatarPhotodefault} alt="" className="rounded-lg h-28 w-28 object-cover my-8" />
                        <table className="w-full table-fixed">
                            <tbody>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Nombre</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{`${appointment?.userClientData?.name} ${appointment?.userClientData?.lastNameP} ${appointment?.userClientData?.lastNameM}`}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Teléfono</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.userClientData?.phoneNumber}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Correo electrónico</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.userClientData?.email}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Dirección</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">
                                        {appointment?.userClientData?.addressData ? (
                                            `${appointment?.userClientData?.addressData?.street} ${appointment?.userClientData?.addressData?.exteriorNumber}, ${appointment?.userClientData?.addressData?.neighborhood}, ${appointment?.userClientData?.addressData?.postalCode}, ${appointment?.userClientData?.addressData?.municipality}, ${appointment?.userClientData?.addressData?.state}`
                                        ) : (
                                            '-'
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <h2 className="text-2xl font-semibold mt-5">Sobre el proveedor</h2>
                        <img src={appointment?.userClientData?.pictureData?.fullpath ? appointment?.userClientData?.pictureData?.fullpath : AvatarPhotodefault} alt="" className="rounded-lg h-28 w-28 object-cover my-8" />
                        <table className="w-full table-fixed">
                            <tbody>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Nombre</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.storeData?.tradeName}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Contacto</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.storeData?.tradeName}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Teléfono</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.userClientData?.phoneNumber}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Correo electrónico</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.userClientData?.email}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Dirección</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">
                                        {appointment?.userClientData?.addressData ? (
                                            `${appointment?.userClientData?.addressData?.street} ${appointment?.userClientData?.addressData?.exteriorNumber}, ${appointment?.userClientData?.addressData?.neighborhood}, ${appointment?.userClientData?.addressData?.postalCode}, ${appointment?.userClientData?.addressData?.municipality}, ${appointment?.userClientData?.addressData?.state}`
                                        ) : (
                                            '-'
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="border-dashed border-r-[1px] border-[#E4E3E5] px-10">
                    <div className="flex flex-col h-full">
                        <h2 className="text-2xl font-semibold">Sobre la mascota</h2>
                        <img src={appointment?.petData?.pictureData?.fullpath ? appointment?.petData?.pictureData?.fullpath : PetPhotodefault} alt="" className="rounded-lg h-28 w-28 object-cover my-8" />
                        <table className="w-full table-fixed">
                            <tbody>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Nombre</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.petData?.name}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Tipo de mascota</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.petData?.petTypeData?.name}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Raza</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.petData?.breedData?.name}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Edad</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.petData?.birthDate}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Peso</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.petData?.weight} kg</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Género</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.petData?.sex === 1 ? 'Macho' : 'Hembra'}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Alergias</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.petData?.allergy ?? 'N/A'}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Cuidados especiales</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.petData?.specialCares ?? 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="px-10">
                    <div className="flex flex-col h-full">
                        <h2 className="text-2xl font-semibold">Servicio</h2>
                        <img src={appointment?.serviceData?.picturesData ? appointment?.serviceData?.picturesData[0]?.fullpath : AvatarPhotodefault} alt="" className="rounded-lg h-28 w-28 object-cover my-8" />
                        <table className="w-full table-fixed">
                            <tbody>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Nombre</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.serviceData?.name}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Descripción</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.serviceData?.description}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Folio</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.serviceData?.objectId}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Fecha</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.date}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Hora</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.date}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Categoría</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.serviceData?.serviceTypeData?.name}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Monto</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{formatCurrency(appointment?.price)}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Ganancia</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{formatCurrency(getProfit(appointment?.price, appointment?.feePercentage))}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Ganancia del Proveedor</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{formatCurrency(appointment?.price - getProfit(appointment?.price, appointment?.feePercentage))}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Estatus</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{renderAppointmentStatus(appointment?.status)}</td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <div className="w-80 max-w-full mt-4">
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    appointmentObjectId: appointment?.objectId ?? '',
                                    status: appointment?.stripeStatus ?? ''
                                }}
                                onSubmit={handleSubmit}
                            >
                                {({ touched, errors }) => (
                                    <Form>
                                        <Field
                                            as="select"
                                            name="status"
                                            className={classNames(
                                                "form-select inp-small block w-full px-4 py-3 border placeholder-darkgray text-gray-900 focus:outline-none bg-semigray",
                                                inputBG(touched.status, errors.status)
                                            )}
                                        >
                                            {Object.values(appointmentStatuses)?.map((status, i) => (
                                                <option key={i} value={status}>{status}</option>
                                            ))}
                                        </Field>
                                        <button
                                            className="mt-auto pt-16 font-semibold text-tailgreen text-lg flex items-center"
                                            type="submit"
                                        >
                                            <img src={Pencil2Icon} alt="" className="mr-5" />
                                            Editar estatus
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        </div> */}
                    </div>
                </div>
            </section>
            <section className="border-t-[1px] border-[#E4E3E5] mt-9 flex">
                <NavLink to={-1} className="my-9 ml-auto">
                    <button
                        className="btn-prymary w-[250px] btn-small"
                        type="button"
                    >
                        Regresar
                    </button>
                </NavLink>
            </section>
        </main>
        
    )
}