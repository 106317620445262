import { classNames } from '../utils/CssHelpers'


export default function FieldInput({ field, form, meta, className, label, type, disabled = false }) {

    const empty = !field.value

    return (
        <div className={classNames('relative', className)}>
            <input
                disabled={disabled}
                type={type}
                placeholder={label}
                className={classNames(
                    "w-full inp-small px-4 bg-semigray",
                    empty ? "inp-small_empty" : "inp-small_notempty",
                    (meta.touched && !disabled) ? (meta.error ?
                        "ring-darkred border-darkred focus:border-darkred focus:ring-darkred "
                        :
                        "ring-tailgreen border-tailgreen focus:border-tailgreen focus:ring-tailgreen"
                    ) : "ring-gray-300 border-gray-300 focus:border-gray-300 focus:ring-gray-300"
                )}
                {...field}
            />
            {meta.touched && meta.error && <div className="text-darkred font-semibold text-sm mt-1">{meta.error}</div>}
            <label
                htmlFor="password"
                className={classNames(
                    empty ? 'sr-only' : 'inp-small_label left-4',
                    (meta.touched && !disabled) ? (meta.error ?
                        "text-darkred"
                        :
                        "text-tailgreen"
                    ) : "text-gray-300"
                )}>
                {label}
            </label>

        </div>
    )
}
