import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { errorAlert, successAlert, unauthorizedUser } from '../../utils/AlertToast'
import { api } from '../../utils/ApiMethods'

const useMarkNotificationAsSeen = () => {
    const { authAction, auth } = useAuth()
    const [loading, setLoading] = useState(false)
    const handleMarkAsSeen = async (values) => {
        setLoading(true)
        try {
            const response = await api(auth.token)
                .post('markNotificationAsSeen')
                .sendForm(values)
            const data = await response.json()
            setLoading(false)
            if (response.status === 200) {
                successAlert('', data.message)
                return true
            }
            else if (response.status === 401)
                unauthorizedUser(authAction, data.message)
            else
                errorAlert('', data.message)
        } catch (e) {
            console.log(e)
            setLoading(false)
            errorAlert('', 'Error descocido')
        }
        return false
    }
    return { handleMarkAsSeen, loading }
}

export default useMarkNotificationAsSeen