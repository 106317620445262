import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { classNames } from '../utils/CssHelpers'


export default function Pagination({ paginate, onChangue }) {

    const previus = paginate?.prev_page_url ? true : false
    const next = paginate?.next_page_url ? true : false

    return (

        <div className="mt-2">

            <div className="flex-1 flex justify-between sm:hidden">
                <button
                    disabled={!previus}
                    onClick={() => onChangue(paginate.current_page - 1)}
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    Anterior
                </button>
                <button
                    disabled={!next}
                    onClick={() => onChangue(paginate.current_page + 1)}
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    Siguiente
                </button>
            </div>

            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">

                <div>
                    <p className="text-sm text-gray-700">
                        Mostrando <span className="font-medium">{paginate.from}</span> al <span className="font-medium">{paginate.to}</span> de{' '}
                        <span className="font-medium">{paginate.total}</span> resultados
                    </p>
                </div>

                <div>
                    <nav className="relative z-0 inline-flex -space-x-px" aria-label="Pagination">
                        {
                            paginate.links.map((link, i) => i === 0 ?
                                <button
                                    key={"link" + i}
                                    disabled={!link.url || link.active}
                                    onClick={() => onChangue(paginate.current_page - 1)}
                                    className={classNames(
                                        "text-sm font-medium px-2 py-2",
                                        link.active ? "text-darkgreen" :
                                            "text-tailgreen hover:text-mediumgreen disabled:text-mediumgreen"
                                    )}
                                >
                                    <span className="sr-only">Previous</span>
                                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                                : (
                                    i !== (paginate.links.length - 1) ?
                                        <button
                                            key={"link" + i}
                                            disabled={!link.url || link.active}
                                            onClick={() => onChangue(link.label)}
                                            className={classNames(
                                                "text-sm font-medium px-2 py-2",
                                                link.active ? "text-darkgreen" :
                                                    "text-tailgreen hover:text-mediumgreen disabled:text-mediumgreen"
                                            )}
                                        >
                                            {link.label}
                                        </button>
                                        :
                                        <button
                                            key={"link" + i}
                                            disabled={!link.url || link.active}
                                            onClick={() => onChangue(paginate.current_page + 1)}
                                            className={classNames(
                                                "text-sm font-medium px-2 py-2",
                                                link.active ? "text-darkgreen" :
                                                    "text-tailgreen hover:text-mediumgreen disabled:text-mediumgreen"
                                            )}
                                        >
                                            <span className="sr-only">Next</span>
                                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>

                                )

                            )
                        }
                    </nav>
                </div>
            </div>
        </div>
    )
}
