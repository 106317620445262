import { differenceInDays, format, formatDistance, formatDistanceStrict } from "date-fns"
import { es } from "date-fns/locale"
import { capitalize } from "./StringUtils"

export const formatDateDistance = date =>
    formatDistance(
        new Date(date),
        new Date(),
        { addSuffix: true, locale: es }
    )

export const getMonthName = date =>
    capitalize(
        format(date, 'LLLL', { locale: es })
    )

export const getDayName = date =>
    capitalize(
        format(date, 'EEEE', { locale: es })
    )

export const getMonthDay = date =>
    format(date, 'd', { locale: es })

export const getTime = date =>
    format(date, 'HH:mm', { locale: es })

// export const format = (date, format = 'dd/MM/yyyy HH:mm:ss') =>
//     format(date, format, { locale: es })

export const myFormatDate = (date, dateFormat) => {
    return format(date, dateFormat, { locale: es })
}

export const getFullYear = date =>
    format(date, 'yyyy', { locale: es })

export const formatDate = (date, dateFormat) => {
    const d = new Date(date)
    const today = new Date()
    const distance = differenceInDays(today, d)
    let df = dateFormat ? dateFormat : 'dd/MM/yyyy HH:mm'
    let suffix = 'h'
    if (distance < 1)
        df = 'HH:mm'

    return `${format(new Date(date), df)} ${suffix}`
}

export const getAge = (birthday) => {
    return formatDistanceStrict(
        birthday,
        new Date(),
        { locale: es, addSuffix: false }
    )
}