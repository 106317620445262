import React, { useEffect, useState, useRef, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import useGetUsers from '../../hooks/users/useGetUsers'
import { useDebounce } from '../../hooks/utils/useDebounce'
import SearchInput from '../../components/SearchInput'
import { EyeIcon, TrashIcon } from '@heroicons/react/solid'
import { Table, Td, Th } from '../../components/Table'
import OrderIcon from '../../components/OrderIcon'
import { Dialog, Switch, Transition } from '@headlessui/react'
import Pagination from '../../components/Pagination'
import { classNames } from '../../utils/CssHelpers'
import useUpdateUserStatus from '../../hooks/users/useUpdateUserStatus'
import AvatarPhotodefault from '../../assets/images/png/app/AvatarPhotodefault.png'
import useDeleteUser from '../../hooks/users/UseDeleteUser'
import { ExclamationIcon } from '@heroicons/react/outline'

export const UsersPage = () => {

    const getUsers = useGetUsers()
    const updateUserStatus = useUpdateUserStatus()
    const deleteUser = useDeleteUser();

    const [search, setSearch] = useState('')
    const fieldSearch = { value: search, onChange: (e) => setSearch(e.target.value) }
    const debouncedSearch = useDebounce(search, 500);

    const [user, setUser] = useState(null)
    const [openModalDelete, setOpenModalDelete] = useState(false)
    const cancelButtonModalDeleteRef = useRef(null)

    const showModalDelete = (store) => {
        setUser(store)
        setOpenModalDelete(true)
    }

    const deleteUserClient = async (objectId) => {
        const r = await deleteUser.handleDeleteUser(objectId)
        if (r)
            getUsers.setParams({ ...getUsers.params })
        setOpenModalDelete(false)
    }
        

    const onChangeField = (field) => {
        getUsers.setParams({
            ...getUsers.params,
            sortField: field,
            sortDir: getUsers.params.sortDir * -1
        })
    }

    const onChangeStatus = (e) => {
        const isActiveFilterAux = e.target.value
        const { isActiveFilter, ...resp } = getUsers.params
        getUsers.setParams({
            ...resp,
            pageNum: 0,
            ...(isActiveFilterAux !== '' ? { isActiveFilter: isActiveFilterAux } : {}),
        })
    }

    const updateStatus = async (user) => {

        const resp = await updateUserStatus.handleUpdateUserStatus({
            userObjectId: user.objectId,
            status: user.status === 1 ? 2 : 1
        })
        if (resp)
            getUsers.setParams({ ...getUsers.params, })  /*  */
    }

    const onChangePage = (p) => {
        getUsers.setParams({
            ...getUsers.params,
            pageNum: (p - 1)
        })
    }

    useEffect(() => {
        getUsers.handleGetUsers()
        return () => { };
    }, [getUsers.params])

    useEffect(() => {
        getUsers.setParams({
            ...getUsers.params,
            filter: debouncedSearch,
            pageNum: 0
        })
        return () => { };
    }, [debouncedSearch])

    return (

        <div className="-my-2 sm:-mx-6 lg:-mx-8">

                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">

                    <div className='flex mb-5 items-center'>

                        <div className='w-2/3 lg:w-1/2'>

                            <SearchInput
                                field={fieldSearch}
                                meta={{ touched: search }}
                                label='Buscar'
                            ></SearchInput>

                        </div>

                    </div>

                    <div className='grid grid-cols-1 pb-5'>
                        {
                            getUsers.loading || deleteUser.loading ?
                                <div className='flex justify-center mt-20'>
                                    <p className='h2'> Cargando...</p>
                                </div>
                                :
                                <>
                                    {
                                        (getUsers.users && getUsers.users.length !== 0) ?
                                            <Table>
                                                <thead className="">
                                                    <tr>
                                                        <Th className="px-6 min-w-[100px]">
                                                            <OrderIcon
                                                                field={"users.identifier"}
                                                                params={getUsers.params}
                                                                onChange={onChangeField}
                                                            >ID</OrderIcon>
                                                        </Th>
                                                        <Th className="px-6 min-w-[100px]">
                                                            <OrderIcon
                                                                field={"users.name"}
                                                                params={getUsers.params}
                                                                onChange={onChangeField}
                                                            >Nombre</OrderIcon>
                                                        </Th>
                                                        <Th className="px-6 min-w-[100px]">
                                                            Dirección
                                                        </Th>
                                                        <Th className="px-6 min-w-[100px]" >
                                                            <OrderIcon
                                                                field={"users.phoneNumber"}
                                                                params={getUsers.params}
                                                                onChange={onChangeField}
                                                            >Teléfono</OrderIcon>
                                                        </Th>
                                                        <Th className="px-6 min-w-[100px]" >
                                                            <OrderIcon
                                                                field={"users.email"}
                                                                params={getUsers.params}
                                                                onChange={onChangeField}
                                                            >Email</OrderIcon>
                                                        </Th>

                                                        <Th className="px-6 min-w-[100px]">
                                                            No. de Mascotas
                                                        </Th>

                                                        <Th className="px-6 min-w-[100px]" >
                                                            <select
                                                                className='multiselect__input bg-tailgreen text-white uppercase font-semibold text-sm pl-0'
                                                                onChange={onChangeStatus}
                                                                value={getUsers.params.isActiveFilter}
                                                            >
                                                                <option value="" className='option'>Estatus</option>
                                                                <option value="1" className='option'>Activo</option>
                                                                <option value="2" className='option'>Inactivo</option>
                                                            </select>
                                                        </Th>
                                                        <Th scope="col" className="px-6 py-3">
                                                        </Th>
                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {getUsers.users.map((user, i) => (
                                                        <tr key={user.objectId} className=''>

                                                            <Td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="table-text">{user.identifier}</div>
                                                            </Td>

                                                            <Td className="px-6 py-4 whitespace-nowrap">

                                                                <div className="flex items-center">
                                                                    <div className="flex-shrink-0 h-20 w-20">
                                                                        <img
                                                                            className="h-20 w-20 rounded-full"
                                                                            src={user.pictureData?.fullpath || AvatarPhotodefault}
                                                                            onError={(e) => e.target.src = AvatarPhotodefault}
                                                                            alt="" />
                                                                    </div>
                                                                    <div className="ml-4">
                                                                        <div className="table-text">{user.name} {user.lastNameP} {user.lastNameM}</div>
                                                                    </div>
                                                                </div>
                                                            </Td>
                                                            <Td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="table-text">
                                                                    {user?.addressData?.street}{" "}
                                                                    {user?.addressData?.interiorNumber}</div>
                                                                <div className="table-text">
                                                                    {user?.addressData?.municipality}{" "}
                                                                    {user?.addressData?.city}
                                                                </div>
                                                            </Td>
                                                            <Td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="table-text">{user.phoneNumber}</div>
                                                            </Td>
                                                            <Td className="px-6 py-4 whitespace-nowrap">

                                                                <div className="table-text">{user.email}</div>
                                                            </Td>
                                                            <Td className="px-6 py-4 whitespace-nowrap">

                                                                <div className="table-text">{user.petsAmount}</div>
                                                            </Td>
                                                            <Td className="px-6 py-4 whitespace-nowrap">
                                                                <Switch.Group>
                                                                    <div className="flex items-center">
                                                                        <Switch
                                                                            checked={user.status == 1}
                                                                            onChange={(v) => updateStatus(user)}
                                                                            className={`${user.status == 1 ? 'bg-green-600' : 'bg-darkred'
                                                                                } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
                                                                        >
                                                                            <span
                                                                                className={`${user.status == 1 ? 'translate-x-6' : 'translate-x-1'
                                                                                    } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                                                                            />
                                                                        </Switch>
                                                                        <p className={
                                                                            classNames(
                                                                                "ml-2",
                                                                                user.status == 1 ? 'text-green-600' : 'text-darkred')
                                                                        }>
                                                                            {user.status == 1 ? 'Activo' : 'Inactivo'}
                                                                        </p>
                                                                    </div>
                                                                </Switch.Group>
                                                            </Td>
                                                            <Td>
                                                                <div className="flex flex-row justify-center items-center">
                                                                    <NavLink
                                                                        to={`/usuarios/`+user.objectId}
                                                                        className='w-full flex flex-row items-center justify-center group lg:justify-end'>
                                                                        <div className="text-darkgreen hover:text-tailgreen mt-1">
                                                                            <EyeIcon className='w-6 h-6 mr-3' />
                                                                        </div>
                                                                    </NavLink>
                                                                    <button
                                                                        onClick={() => showModalDelete(user)}
                                                                        className="text-tailgreen hover:text-mediumgreen mt-1"
                                                                    >
                                                                        <TrashIcon className='w-6 h-6 mr-5' />
                                                                    </button>
                                                                </div>
                                                            </Td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            :
                                            <div className='flex justify-center mt-20'>
                                                <p className='h2'>  No hay resultados</p>
                                            </div>
                                    }

                                    {
                                        (getUsers.pagination && getUsers.pagination.last_page !== 1) ?
                                            <Pagination paginate={getUsers.pagination} onChangue={onChangePage}></Pagination>
                                            : null
                                    }
                                </>
                        }
                    </div>

                </div>

                <Transition.Root show={openModalDelete} as={Fragment}>
                    <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonModalDeleteRef} onClose={setOpenModalDelete}>
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                            </div>
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                                    Eliminar usuario
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        Deseas eliminar al usuario {user?.name} {user?.lastNameP} {user?.lastNameM}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-300"
                                            onClick={() => deleteUserClient(user?.objectId)}
                                            disabled={deleteUser.loading}
                                        >
                                            Eliminar
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-300"
                                            onClick={() => setOpenModalDelete(false)}
                                            disabled={deleteUser.loading}
                                            ref={cancelButtonModalDeleteRef}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>
            </div>
    )
}