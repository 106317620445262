import { React, useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import useGetUser from '../../hooks/users/useGetUser'
import AvatarPhotodefault from '../../assets/images/png/app/AvatarPhotodefault.png'
import PetPhotodefault from '../../assets/images/png/app/PetPhotoDefault.png'
import { NavLink } from 'react-router-dom'
import { classNames } from '../../utils/CssHelpers'
import { ChevronRightIcon } from '@heroicons/react/solid'

export const UserPage = () => {

    const { id } = useParams()
    const { handleGetUser, user } = useGetUser()
    const [ pet, setPet] = useState(null)

    const onSelectPet = (pet) => {
        setPet(pet)
    }

    useEffect(() => {
        handleGetUser(id)
    }, [])

    useEffect(() => {
        if(user) {
            onSelectPet(user?.petsData?.[0] ?? null);
        } 
    }, [user])

    return (

        <main className="mt-10">
            <section className="grid lg:grid-cols-3">
                <div className="border-dashed border-r-[1px] border-[#E4E3E5] px-10">
                    <div className="flex flex-col h-full">

                        <h2 className="text-2xl font-semibold">Sobre el cliente</h2>
                        <img src={user?.pictureData?.fullpath ? user?.pictureData?.fullpath : AvatarPhotodefault} alt="" className="rounded-lg h-28 w-28 object-cover my-8" />
                        <table className="w-full table-fixed">
                            <tbody>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Nombre</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{user?.name} {user?.lastNameP} {user?.lastNameM}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Teléfono</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{user?.phoneNumber}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Correo electrónico</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{user?.email}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Dirección</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">
                                        {user?.addressData ? (
                                            `${user?.addressData?.street} ${user?.addressData?.exteriorNumber}, ${user?.addressData?.neighborhood}, ${user?.addressData?.postalCode}, ${user?.addressData?.municipality}, ${user?.addressData?.state}`
                                        ) : (
                                            '-'
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                        <br></br><h2 className="text-2xl font-semibold">Mascotas</h2>
                        <div className='max-h-[150px] overflow-auto cursor-pointer'>
                            {user?.petsData.map((myPet, i) => (   
                                <div key={i} className={classNames( myPet.objectId == pet?.objectId ? 'grid grid-cols-12 mr-3 bg-[#F7F7F7]' : 'grid grid-cols-12 mr-3')} onClick={() => onSelectPet(myPet)}>
                                    <div className="pt-4  col-span-12 border-t-[1px] border-[#E4E3E5] flex"></div> 
                                    <div className={classNames( myPet.objectId == pet?.objectId ? 'col-span-11 font-semibold' : 'col-span-11 font-semibold text-darkgray')}>{myPet?.name}</div>
                                    <div className={classNames( myPet.objectId == pet?.objectId ? 'col-span-1 font-semibold text-right' : 'col-span-1 font-semibold text-darkgray text-right')}><ChevronRightIcon className='w-6 h-6'/> </div>
                                </div> 
                            ))}
                        </div>

                    </div>
                </div>
                <div className="border-dashed border-r-[1px] border-[#E4E3E5] px-10">
                    <div className="flex flex-col h-full">
                        <h2 className="text-2xl font-semibold">Sobre la mascota</h2>
                        <img src={pet?.pictureData?.fullpath ? pet?.pictureData?.fullpath : PetPhotodefault} alt="" className="rounded-lg h-28 w-28 object-cover my-8" />
                        <table className="w-full table-fixed">
                            <tbody>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Nombre</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{pet?.name}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Tipo de mascota</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{pet?.petTypeData?.name}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Raza</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{pet?.breedData?.name}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Edad</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{pet?.birthDate}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Peso</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{pet?.weight} kg</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Género</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{pet?.sex === 1 ? 'Macho' : 'Hembra'}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Alergias</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{pet?.allergy ?? 'N/A'}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Cuidados especiales</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{pet?.specialCare ?? 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="px-10">
                    <div className="flex flex-col h-full">
                        <h2 className="text-2xl font-semibold">Servicios Contratados</h2>
                        <div className='max-h-[550px] overflow-auto'>
                            {user?.appointmentsData.map((appointment, i) => (   
                                <NavLink key={i} to={`/historico/`+appointment?.objectId+`/detalle`} className="my-9 ml-auto">
                                    <div  className="grid grid-cols-6 mr-3">
                                    <div className="my-3  col-span-6 border-t-[1px] border-[#E4E3E5] flex"></div> 
                                    <div className="col-span-4 font-semibold">{appointment?.serviceData?.name}</div>
                                    <div className="col-span-2 font-semibold text-darkgray text-right">{appointment?.petData?.name}</div>
                                    <div className="col-span-6 font-semibold text-darkgray">{appointment?.date} - {appointment?.duration} min</div>
                                    <div className="col-span-6 font-semibold text-darkgray">{appointment?.storeData?.tradeName}</div>
                                </div> 
                                </NavLink>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <section className="border-t-[1px] border-[#E4E3E5] mt-9 flex">
                <NavLink to="/user" className="my-9 ml-auto">
                    <button
                        className="btn-prymary w-[250px] btn-small"
                        type="button"
                    >
                        Regresar
                    </button>
                </NavLink>
            </section>
        </main>
    
    )
}