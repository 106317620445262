import { useState } from "react"
import { useAuth } from "../../context/AuthContext"
import { errorAlert, successAlert } from "../../utils/AlertToast"
import { api } from "../../utils/ApiMethods"

const useDeleteUser = () => {

    const [loading, setLoading] = useState(false)
    const { auth } = useAuth()

    const handleDeleteUser = async (userObjectId) => {

        setLoading(true)

        try {

            const response = await api(auth.token).post('deleteUser').sendJson({ userObjectId })
            const result = await response.json()

            setLoading(false)

            if (response.status === 200) {
                successAlert('', 'Usuario eliminado')
                return true
            }
            else
                errorAlert('', result.message)

        } catch (e) {

            errorAlert()
            setLoading(false)

        }

        return false

    }

    return { handleDeleteUser, loading }
}

export default useDeleteUser