import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { errorAlert, unauthorizedUser } from '../../utils/AlertToast'
import { api } from '../../utils/ApiMethods'

const useGetAppointmentById = () => {

    const { authAction, auth } = useAuth()
    const [loading, setLoading] = useState(false)
    const [appointment, setAppointment] = useState()

    const handleGetAppointmentById = async (values) => {
        setLoading(true)
        try {

            const response = await api(auth.token)
                .getPost('getAppointmentByIdForAdmin')
                .sendJson({ ...values })
            const data = await response.json()

            setLoading(false)
            if (response.status === 200) {
                setAppointment(data)
                console.log(data)
                return true
            }
            unauthorizedUser(response.status, authAction, data.message)
        } catch (e) {
            setLoading(false)
            errorAlert()
        }

        return false
    }

    return { handleGetAppointmentById, loading, appointment }

}

export default useGetAppointmentById