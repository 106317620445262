import React, { useEffect, useState } from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import useGetServices from '../../hooks/stores/useGetServices'
import SearchInput from '../../components/SearchInput'
import { useDebounce } from '../../hooks/utils/useDebounce'
import { Table, Td, Th } from '../../components/Table'
import OrderIcon from '../../components/OrderIcon'
import useGetAllServicesTypes from '../../hooks/services/useGetAllServicesTypes'
import Pagination from '../../components/Pagination'
import useGetAllPetTypes from '../../hooks/services/useGetAllPetTypes'

export const VendorServicesPage = () => {

    const { id } = useParams()
    const getServices = useGetServices()
    const getAllServiceTypes = useGetAllServicesTypes()
    const getAllPetTypes = useGetAllPetTypes()
    
    const [search, setSearch] = useState('')
    const fieldSearch = { value: search, onChange: (e) => setSearch(e.target.value) }
    const debouncedSearch = useDebounce(search, 500);

    const formatCurrency = amount => {
        const options = { style: 'currency', currency: 'USD' };
        const numberFormat = new Intl.NumberFormat('en-US', options);

        return numberFormat.format(amount);
    }

    const onChangePage = (p) => {
        getServices.setParams({
            ...getServices.params,
            pageNum: (p - 1)
        })
    }

    const onChangeField = (field) => {
        getServices.setParams({
            ...getServices.params,
            sortField: field,
            sortDir: getServices.params.sortDir * -1
        })
    }

    const onChangeServiceType = (e) => {
        const serviceTypeFilterAux = e.target.value
        const { serviceTypeFilter, ...resp } = getServices.params
        getServices.setParams({
            ...resp,
            pageNum: 0,
            ...(serviceTypeFilterAux !== '' ? { serviceTypeFilter: serviceTypeFilterAux } : {}),
        })
    }

    const onChangePetType = (e) => {
        const petTypeFilterAux = e.target.value
        const { petTypeFilter, ...resp } = getServices.params
        getServices.setParams({
            ...resp,
            pageNum: 0,
            ...(petTypeFilterAux !== '' ? { petTypeFilter: petTypeFilterAux } : {}),
        })
    }

    useEffect(() => {
        getServices.handleGetServices(id)
    }, [getServices.params])

    useEffect(() => {
        getAllServiceTypes.handleGetAllServiceTypes()
        getAllPetTypes.handleGetAllPetTypes()
        return () => { };
    }, [])

    useEffect(() => {
        getServices.setParams({
            ...getServices.params,
            filter: debouncedSearch,
            pageNum: 0
        })
        return () => { };
    }, [debouncedSearch])

    return (

        <div className="-my-2 sm:-mx-6 lg:-mx-8">

            <section className='mb-5 mt-2' style={{display: 'flex', justifyContent: 'center'}}>
            <Link to={`/proveedores/${id}/detalle`} replace className='font-bold text-extradarkgray mr-3'> Información General</Link>
            <Link to={`/proveedores/${id}/servicios`} replace className='font-bold text-tailgreen mr-3 ml-3'> Listado de Servicios</Link>
            <Link to={`/proveedores/${id}/ventas`} replace className='font-bold text-extradarkgray ml-3'>Historial de Ventas</Link>
            </section>

            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">

                <div className='flex mb-5 items-center'>

                    <div className='w-2/3 lg:w-1/2'>

                        <SearchInput
                            field={fieldSearch}
                            meta={{ touched: search }}
                            label='Buscar'
                        ></SearchInput>

                    </div>

                </div>

                <div className='grid grid-cols-1 pb-5'>
                    {
                        getServices.loading ?
                            <div className='flex justify-center mt-20'>
                                <p className='h2'> Cargando...</p>
                            </div>
                            :
                            <>
                                {
                                    (getServices.services && getServices.services.length !== 0) ?
                                        <Table>
                                            <thead className="">
                                                <tr>
                                                    <Th className="px-6 min-w-[100px]">
                                                        <OrderIcon
                                                            field={"services.identifier"}
                                                            params={getServices.params}
                                                            onChange={onChangeField}
                                                        >ID</OrderIcon>
                                                    </Th>
                                                    <Th className="px-6 min-w-[100px]">
                                                        <OrderIcon
                                                            field={"services.name"}
                                                            params={getServices.params}
                                                            onChange={onChangeField}
                                                        >Servicio</OrderIcon>
                                                    </Th>
                                                    <Th className="px-6 min-w-[100px]">
                                                        <OrderIcon
                                                            field={"services.description"}
                                                            params={getServices.params}
                                                            onChange={onChangeField}
                                                        >Descripción</OrderIcon>
                                                    </Th>
                                                    <Th className="px-6 min-w-[100px]" >
                                                        <select
                                                            className='multiselect__input bg-tailgreen text-white uppercase font-semibold text-sm pl-0'
                                                            onChange={onChangeServiceType}
                                                        >
                                                            <option value="" className=''>Categoría de servicio</option>
                                                            {
                                                                getAllServiceTypes.types.map(t => (
                                                                    <option value={t.objectId} key={t.objectId}>{t.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </Th>
                                                    <Th className="px-6 min-w-[100px]" >
                                                        <select
                                                            className='multiselect__input bg-tailgreen text-white uppercase font-semibold text-sm pl-0'
                                                            onChange={onChangePetType}
                                                        >
                                                            <option value="" className=''>Tipo de mascota</option>
                                                            {
                                                                getAllPetTypes.types.map(t => (
                                                                    <option value={t.objectId} key={t.objectId}>{t.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </Th>
                                                    <Th className="px-6 min-w-[100px]" >
                                                        <OrderIcon
                                                            field={"services.duration"}
                                                            params={getServices.params}
                                                            onChange={onChangeField}
                                                        >Duración</OrderIcon>
                                                    </Th>
                                                    <Th className="px-6 min-w-[100px]" >
                                                        <OrderIcon
                                                            field={"services.price"}
                                                            params={getServices.params}
                                                            onChange={onChangeField}
                                                        >Precio</OrderIcon>
                                                    </Th>
                                                </tr>
                                            </thead>
                                            <tbody className="">
                                                {getServices.services.map((service, i) => (
                                                    <tr key={service.objectId} className=''>

                                                        <Td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="table-text">{service.identifier}</div>
                                                        </Td>
                                                        <Td className="px-6 py-4 w-96">
                                                            <div className="table-text"><b>{service?.name}</b></div>
                                                        </Td>
                                                        <Td className="px-6 py-4 w-96">
                                                            <div className="table-text">{service?.description}</div>
                                                        </Td>
                                                        <Td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="table-text">{service?.serviceTypeData?.name}</div>
                                                        </Td>
                                                        <Td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="table-text">{service?.petTypeData?.name}</div>
                                                        </Td>
                                                        <Td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="table-text">{service?.duration}min</div>
                                                        </Td>
                                                        <Td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="table-text">{formatCurrency(service?.price)}</div>
                                                        </Td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                        :
                                        <div className='flex justify-center mt-20'>
                                            <p className='h2'>  No hay resultados</p>
                                        </div>
                                }

                                {
                                    (getServices.pagination && getServices.pagination.last_page !== 1) ?
                                        <Pagination paginate={getServices.pagination} onChangue={onChangePage}></Pagination>
                                        : null
                                }
                            </>
                    }
                </div>

            </div>

        </div>

    )
}