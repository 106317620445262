import { ReactComponent as ArrowsIcon } from '../assets/images/svg/custom/arrows/arrows.svg'
import { ReactComponent as ArrowDownIcon } from '../assets/images/svg/custom/arrows/arrow_down.svg'
import { ReactComponent as ArrowUpIcon } from '../assets/images/svg/custom/arrows/arrow_up.svg'
import { fv } from "../utils/AuxVars"

const OrderIcon = ({ field, params, sortDir = 1, children, onChange = fv }) => (
    <div className='cursor-pointer flex flex-row items-center' onClick={() => onChange(field)}>
        <div className="mr-1 pb-1">
            {
                field !== params.sortField ?
                    <ArrowsIcon className="w-3 h-3 fill-white"></ArrowsIcon> :
                    (
                        params.sortDir === sortDir ?
                            <ArrowDownIcon className="w-3 h-3 fill-white"></ArrowDownIcon> :
                            <ArrowUpIcon className="w-3 h-3 fill-white"></ArrowUpIcon>
                    )
            }
        </div>
        {children}
    </div>
)

export default OrderIcon