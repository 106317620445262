import { SearchIcon } from '@heroicons/react/solid'
import { classNames } from '../utils/CssHelpers'


export default function SearchInput({ field, form, meta, className = '', label = '', type = 'text', disabled = false }) {

    const empty = !field.value

    return (
        <div className={classNames('relative', className)}>
            <input
                disabled={disabled}
                type={type}
                placeholder={label}
                className={classNames(
                    "w-full inp-small px-8",
                    empty ? "inp-small_empty" : "inp-small_notempty text-extradarkgray",
                    meta.touched ? (meta.error ?
                        "ring-darkred border-darkred focus:border-darkred focus:ring-darkred "
                        :
                        "ring-tailgreen border-tailgreen focus:border-tailgreen focus:ring-tailgreen"
                    ) : "ring-gray-300 border-gray-300 focus:border-gray-300 focus:ring-gray-300"
                )}
                {...field}
            />
            {meta.touched && meta.error && <div className="text-darkred font-semibold text-sm mt-1">{meta.error}</div>}
            <label
                className={classNames(
                    empty ? 'sr-only' : 'inp-small_label left-8',
                    meta.touched ? (meta.error ?
                        "text-darkred"
                        :
                        "text-tailgreen"
                    ) : "text-extradarkgray"
                )}>
                {label}
            </label>
            <span className={classNames(
                "absolute inset-y-0 left-0 flex items-center pl-2",
                meta.touched ? (meta.error ?
                    "text-darkred"
                    :
                    "text-tailgreen"
                ) : "text-extradarkgray")}>
                <SearchIcon className='w-5 h-5' />
            </span>
        </div>
    )
}
