import 'core-js/es/promise'
import 'core-js/es/set'
import 'core-js/es/map'
import * as Yup from 'yup'

const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string().notRequired(),
    newPassword: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
            [Yup.ref('password')],
            'Las contraseñas no coinciden'
        ).required('Elemento requerido')
    })
})

export { ResetPasswordSchema }