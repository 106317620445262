import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'

export const PrivateRoute = ({ children }) => {

    const { auth } = useAuth()
    const { pathname, search } = useLocation()

    localStorage.setItem('lastPath', pathname + search)

    return auth.logged
        ? children
        : <Navigate to="/login" />
}