import { useState } from "react"
import { useAuth } from "../../context/AuthContext"
import { unauthorizedUser } from "../../utils/AlertToast"
import { api } from "../../utils/ApiMethods"

const useGetReviews = () => {

    const { auth, authAction } = useAuth()

    const [ reviews, setReviews ] = useState(null)

    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(null)
    const [params, setParams] = useState({
        pageNum: 0,
        perPage: 10,
        sortField: 'reviews.created_at',
        filter: '',
        sortDir: -1
    })

    const handleGetReviews = async () => {

        setLoading(true)

        try {

            const response = await api(auth.token).getPost('getReviews').sendJson({ ...params })
            const result = await response.json()
            
            if (response.status === 200) {
                const { data, ...rest } = result
                setReviews(data ?? [])
                setPagination(rest ?? [])
                console.log(data)
            }

            unauthorizedUser(response.status, authAction, result.message)
            setLoading(false)

        } catch (e) {

            setLoading(false)

        }

    }

    return { handleGetReviews, loading, reviews, pagination, params, setParams }

}

export default useGetReviews