import { link, objectFormData } from "./RoutesApi"

const getOptions = (token = null, options = {}) => token ? {
    ...options,
    headers: {
        Authorization: token,
        ...(options.headers ?? {})
    }
} : options


const addHeader = (options = {}, name, value) => {
    if (!options.headers)
        options.headers = {};
    options.headers[name] = value;
    return options
}

const bodyFormat = (link, options) => ({
    sendJson: (body) => fetch(link, { ...addHeader(options, "Content-Type", "application/json"), body: JSON.stringify(body) }),
    sendForm: (body) => fetch(link, { ...options, body: objectFormData(body) }),
    send: () => fetch(link, options),
})

const methodApi = (options) => ({
    get: (route) => bodyFormat(link(route), { ...options, }),
    post: (route) => bodyFormat(link(route), { ...options, method: 'POST', }),
    put: (route) => bodyFormat(link(route), { ...options, method: 'PUT', }),
    delete: (route) => bodyFormat(link(route), { ...options, method: 'DELETE', }),
    getPost: (route) => bodyFormat(link(route) + "?_method=GET", { ...options, method: 'POST', }),
    putPost: (route) => bodyFormat(link(route) + "?_method=PUT", { ...options, method: 'POST', }),
})

export const api = (token, options) => methodApi(getOptions(token, options))