import { Navigate, Route, Routes } from 'react-router-dom'
import { RegisterRouteLayout } from '../layouts/RegisterRouteLayout'
import { HistoricalPage } from '../pages/historical/HistoricalPage'
import { AppointmentDetailPage } from '../pages/historical/AppointmentDetailPage'
import { HomePage } from '../pages/home/HomePage'
import { ProfileUserPage } from '../pages/profile/ProfileUserPage'
import { ReviewsPage } from '../pages/reviews/ReviewsPage'
import { UserCreatePage } from '../pages/users/UserCreatePage'
import { UserEditPage } from '../pages/users/UserEditPage'
import { UserPage } from '../pages/users/UserPage'
import { UsersPage } from '../pages/users/UsersPage'
import { VendorCreatePage } from '../pages/vendors/VendorCreatePage'
import { VendorsPage } from '../pages/vendors/VendorsPage'
import { VendorDetailPage } from '../pages/vendors/VendorDetailPage'
import { VendorServicesPage } from '../pages/vendors/VendorServicesPage'
import { VendorSalesPage } from '../pages/vendors/VendorSalesPage'
import { VendorUpdatePage } from '../pages/vendors/VendorUpdatePage'

export const RegisterRouter = () => (
    <RegisterRouteLayout>
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/perfil" element={<ProfileUserPage />} />
            <Route path="/usuarios" element={<UsersPage />} />
            <Route path="/usuarios/:id" element={<UserPage />} />
            <Route path="/usuarios/:id/editar" element={<UserEditPage />} />
            <Route path="/usuarios/crear" element={<UserCreatePage />} />
            <Route path="/proveedores/crear" element={<VendorCreatePage />} />
            <Route path="/proveedores" element={<VendorsPage />} />
            <Route path="/proveedores/:id/detalle" element={<VendorDetailPage />} />
            <Route path="/proveedores/:id/servicios" element={<VendorServicesPage />} />
            <Route path="/proveedores/:id/ventas" element={<VendorSalesPage />} />
            <Route path="/proveedores/:id/actualizar" element={<VendorUpdatePage />} />
            <Route path="/historico" element={<HistoricalPage />} />
            <Route path="/historico/:id/detalle" element={<AppointmentDetailPage />} />
            <Route path="/reviews" element={<ReviewsPage />} />
            <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
    </RegisterRouteLayout>
)