import React, {useEffect} from 'react'
import Logo from '../../assets/images/svg/app/Logo.svg'
import { useParams } from 'react-router-dom'
import useConfirmAccount from '../../hooks/password/useConfirmAccount'

export const ConfirmAccountPage = () => {

    const { code } = useParams()
    const confirmAccount = useConfirmAccount()

    useEffect(() => {
        confirmAccount.handleConfirmAccount(code)
        return () => { };
    }, [])

    return (
        <div className="min-h-screen flex items-center justify-center px-4 sm:px-6 lg:px-8 py-12 login">
            <div className="w-full max-w-lg z-10">
                <div>
                    <img
                        className="mx-auto w-auto h-28"
                        src={Logo}
                        alt="Workflow"
                    />
                </div>
                {
                    confirmAccount.loading ? '' :
                    <div className='text-tailgreen mt-10 text-2xl font-semibold text-center'>
                        {
                            confirmAccount.status ? 'Tu cuenta se ha verificado, ya puedes iniciar sesión en tu aplicación movil.'
                            : 'El código de confirmación no es válido o ya fue utilizado.'
                        }
                    </div>
                }
                
            </div>
        </div>
    )
}