const apiUrl = process.env.REACT_APP_API_URL || ""

export const link = route => { //Función para crear la url
    return apiUrl + route
}

export const objectFormData = params => {         //Función para convertir la data en un formdata
    let keys = Object.keys(params)
    let values = Object.values(params)
    let formdata = new FormData()
    for (let i = 0; i < keys.length; i++) {
        if (values[i] === null)
            formdata.append(keys[i], [])
        else if (Array.isArray(values[i])) {
            values[i].forEach(v => formdata.append(keys[i], v))
        }
        else
            formdata.append(keys[i], values[i])
    }
    return formdata
}
