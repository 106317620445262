import { useEffect, useState } from "react"
import { useAuth } from "../../context/AuthContext"
import { unauthorizedUser } from "../../utils/AlertToast"
import { api } from "../../utils/ApiMethods"

const useGetAllProviders = () => {

    const [loading, setLoading] = useState(false)
    const [providers, setProviders] = useState([])
    const [params, setParams] = useState({})

    const { auth, authAction } = useAuth()

    const handleGetProviders = async () => {

        setLoading(true)

        try {

            const response = await api(auth.token).getPost('getAllProviders').sendJson(params)

            const result = await response.json()

            if (response.status === 200) {
                setProviders(result)
            }

            unauthorizedUser(response.status, authAction, result.message)

            setLoading(false)

        } catch (e) {

            console.log(e)
            setLoading(false)

        }
    }

    useEffect(() => {
        handleGetProviders()
        return () => { }
    }, [params])

    return { handleGetProviders, loading, providers, params, setParams }

}

export default useGetAllProviders